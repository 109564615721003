import { IProductPremiumCharacteristics } from './../../../../redux/@types/products.d';
import { useState, useEffect } from 'react';
import { message } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import useAppDispatch from '../../../../redux/hooks/useAppDispatch';
import { TableProperties } from '../../../../shared/components/ui/Table/types';
import { selectIsUpdateProductPremiumCharacteristic, selectProductForUpdate, selectProductPremiumCharacteristicRefreshTable, selectProductPremiumCharacteristicsForUpdate } from './../../../../redux/selectors/products.selectors';
import { ProductsPremiumCharacteristicChangeModalState, setIsProductsPremiumCharacteristicForUpdate } from '../../../../redux';
import { createProductPremiumCharacteristic, refreshTableProductsPremiumCharacteristic, updateProductPremiumCharacteristic } from '../../../../redux/actions/products.actions';
import { validateCreateProductPremiumCharacteristics, validateUpdateProductPremiumCharacteristics } from '../../../../helpers';


export const useProductPremiumCharacteristics = () => {
    const dispatch = useAppDispatch();
    const isUpdateProductPremiumCharacteristic = useSelector(
        selectIsUpdateProductPremiumCharacteristic
    );
    const dataUpdateProductPremiumCharacteristic = useSelector(
        selectProductPremiumCharacteristicsForUpdate
    );
    const isRefreshTableProductPremiumCharacteristic = useSelector(
        selectProductPremiumCharacteristicRefreshTable
    );
    const dataProduct = useSelector(selectProductForUpdate);

    const navigateProductPremiumCharacteristicCreate = () => {
        dispatch(setIsProductsPremiumCharacteristicForUpdate(false));
        dispatch(ProductsPremiumCharacteristicChangeModalState())
    };

    const [isLoading, setIsLoading] = useState(false);
    const [tableProps, setTableProps] = useState<TableProperties>();

    const formMethodsCreate = useForm<IProductPremiumCharacteristics>({
        resolver: yupResolver(validateCreateProductPremiumCharacteristics),
    });

    const formMethodsUpdate = useForm<IProductPremiumCharacteristics>({
        resolver: yupResolver(validateUpdateProductPremiumCharacteristics),
    });
    useEffect(() => {
        if (isUpdateProductPremiumCharacteristic) {
            formMethodsCreate.reset();
            formMethodsUpdate.reset({ ...dataUpdateProductPremiumCharacteristic });
        }

        return () => {
            formMethodsUpdate.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdateProductPremiumCharacteristic, dataUpdateProductPremiumCharacteristic]);

    const onSubmitCreateOrUpdate = async (data: IProductPremiumCharacteristics) => {
        // console.log({ data });
        if (isLoading) return;
        setIsLoading(true);
        if (isUpdateProductPremiumCharacteristic) {
          //console.log("Actualiando===>", data);
          await updateProductPremiumCharacteristic({
            id: data.id,
            html_description: data.html_description,
            product_id: dataProduct?.id!,
          })
            .then((res: Partial<IProductPremiumCharacteristics>) => {
              setIsLoading(false);
              // dispatch(mappingUpdatedUser(res))
              if (res.id) {
                dispatch(ProductsPremiumCharacteristicChangeModalState())
                message.success(`Se ha actualizado con éxito la característica premium con id:${res.id}`)
                dispatch(refreshTableProductsPremiumCharacteristic(true));
              } else {
                message.error(res as string);
              }
            })
            .catch((err: any) => {
              setIsLoading(false);
              console.log(err);
              message.error(err.message);
            });
        } else {
          // console.log('Creando===>',data)
          await createProductPremiumCharacteristic({
            html_description: data.html_description,
            product_id: dataProduct?.id!,
          })
            .then((res: any) => {
              setIsLoading(false);
              if (res.id) {
                dispatch(ProductsPremiumCharacteristicChangeModalState())
                message.success(
                  `Se ha creado con éxito la característica premium con id:${res.id}`
                );
                dispatch(refreshTableProductsPremiumCharacteristic(true));
              } else {
                message.error(res as any);
              }
            })
            .catch((err: any) => {
              setIsLoading(false);
              console.log(err);
              message.error(err.message);
            });
        }
    }

    useEffect(() => {
        if (isRefreshTableProductPremiumCharacteristic) {
            tableProps?.refresh();
            dispatch(refreshTableProductsPremiumCharacteristic(false));
        }
    }, [isRefreshTableProductPremiumCharacteristic]);
  return {
    //state
    isUpdateProductPremiumCharacteristic,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    navigateProductPremiumCharacteristicCreate,
  }
}
