import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import useAppDispatch from '../../../../redux/hooks/useAppDispatch';
import { createProductRecipe, productsRecipeChangeModalState, refreshTableProductsRecipe, selectIsUpdateProductRecipes, selectProductForUpdate, selectProductRecipeForUpdate, selectProductRecipeRefreshTable, setIsProductsRecipeForUpdate, updateProductRecipe } from '../../../../redux';
import { TableProperties } from '../../../../shared/components/ui/Table/types';
import { IProductRecipes } from '../../../../redux/@types';
import { validateCreateProductRecipes, validateUpdateProductRecipes } from '../../../../helpers';
import { message } from 'antd';

export const useProductRecipes = () => {
    const dispatch = useAppDispatch();
    const isUpdateProductRecipe = useSelector(
        selectIsUpdateProductRecipes
    );
    const dataUpdateProductRecipe = useSelector(
        selectProductRecipeForUpdate
    );
    const isRefreshTableProductRecipe = useSelector(
        selectProductRecipeRefreshTable
    );
    const dataProduct = useSelector(selectProductForUpdate);

    const navigateProductRecipeCreate = () => {
        dispatch(setIsProductsRecipeForUpdate(false));
        dispatch(productsRecipeChangeModalState())
    };

    const [isLoading, setIsLoading] = useState(false);
    const [tableProps, setTableProps] = useState<TableProperties>();

    const formMethodsCreate = useForm<IProductRecipes>({
        resolver: yupResolver(validateCreateProductRecipes),
    });

    const formMethodsUpdate = useForm<IProductRecipes>({
        resolver: yupResolver(validateUpdateProductRecipes),
    });
    useEffect(() => {
        if (isUpdateProductRecipe) {
            formMethodsCreate.reset();
            formMethodsUpdate.reset({ ...dataUpdateProductRecipe });
        }

        return () => {
            formMethodsUpdate.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdateProductRecipe, dataUpdateProductRecipe]);

    const onSubmitCreateOrUpdate = async (data: IProductRecipes) => {
        console.log({ data });
        if (isLoading) return;
        setIsLoading(true);
        if (isUpdateProductRecipe) {
          //console.log("Actualiando===>", data);
          await updateProductRecipe({
            id: data.id,
            recipe_id: Number(data.recipe_id),
            product_id: dataProduct?.id!,
          })
            .then((res: Partial<IProductRecipes>) => {
              setIsLoading(false);
              // dispatch(mappingUpdatedUser(res))
              if (res.id) {
                dispatch(productsRecipeChangeModalState())
                message.success(`Se ha actualizado con éxito el producto con id:${res.id}`)
                dispatch(refreshTableProductsRecipe(true));
              } else {
                message.error(res as string);
              }
            })
            .catch((err: any) => {
              setIsLoading(false);
              console.log(err);
              message.error(err.message);
            });
        } else {
          // console.log('Creando===>',data)
          await createProductRecipe({
            recipe_id: Number(data.recipe_id),
            product_id: dataProduct?.id!,
          })
            .then((res: any) => {
              setIsLoading(false);
              if (res.id) {
                dispatch(productsRecipeChangeModalState())
                message.success(
                  `Se ha creado con éxito el producto con id:${res.id}`
                );
                dispatch(refreshTableProductsRecipe(true));
              } else {
                message.error(res as any);
              }
            })
            .catch((err: any) => {
              setIsLoading(false);
              console.log(err);
              message.error(err.message);
            });
        }
      };

    useEffect(() => {
        if (isRefreshTableProductRecipe) {
            tableProps?.refresh();
            dispatch(refreshTableProductsRecipe(false));
        }
    }, [isRefreshTableProductRecipe]);
  return {
    //state
    isUpdateProductRecipe,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    navigateProductRecipeCreate,
  }
}
