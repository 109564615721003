import { IGenericArrProduct, IProductCharacteristics2, IProductPremiumCharacteristics, IProductPremiumPreparationSteps, IProductRecipes, IProducts } from "../@types"

export const GET_PRODUCT_FOR_UPDATE = '[ PRODUCTS ] Get Product For Update'

export interface IGetProductsForUpdate {
    type: typeof GET_PRODUCT_FOR_UPDATE
    payload: IProducts
    // DataIProducts
}

export const IS_PRODUCT_FOR_UPDATE = '[ PRODUCTS ] Is Product For Update'

export interface IIsProductsForUpdate {
    type: typeof IS_PRODUCT_FOR_UPDATE
    payload: boolean
}

export const SWITCH_PRODUCTS_REFRESH_TABLE = '[ PRODUCTS ] Switch Products Refresh Table'

export interface ISwitchProductsRefreshTable {
    type: typeof SWITCH_PRODUCTS_REFRESH_TABLE
    payload: boolean
}

export const GET_CATEGORIES_PRODUCTS = '[ PRODUCTS ] Get Categories Products'

export interface IGetCategoriesProducts {
    type: typeof GET_CATEGORIES_PRODUCTS
    payload: IGenericArrProduct[]
}

export const GET_BRANDS_PRODUCTS = '[ PRODUCTS ] Get Brands Products'

export interface IGetBrandsProducts {
    type: typeof GET_BRANDS_PRODUCTS
    payload: IGenericArrProduct[]
}

export const GET_TAX_PRODUCTS = '[ PRODUCTS ] Get Tax Products'

export interface IGetTaxProducts {
    type: typeof GET_TAX_PRODUCTS
    payload: IGenericArrProduct[]
}

//productCharacteristic

export const PRODUCTS_CHARACTERISTICS_CHANGE_MODAL_STATE = '[ PRODUCTS ] Products Characteristics Change Modal State'

export interface IProductsCharacteristicsChangeModalState {
    type: typeof PRODUCTS_CHARACTERISTICS_CHANGE_MODAL_STATE
}

export const GET_PRODUCT_CHARACTERISTICS_FOR_UPDATE = '[ PRODUCTS ] Get Product Characteristics For Update'

export interface IGetProductsCharacteristicsForUpdate {
    type: typeof GET_PRODUCT_CHARACTERISTICS_FOR_UPDATE
    payload: IProductCharacteristics2
    // DataIProducts
}

export const IS_PRODUCT_CHARACTERISTICS_FOR_UPDATE = '[ PRODUCTS ] Is Product Characteristics For Update'

export interface IIsProductsCharacteristicsForUpdate {
    type: typeof IS_PRODUCT_CHARACTERISTICS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_PRODUCTS_CHARACTERISTICS_REFRESH_TABLE = '[ PRODUCTS ] Switch Products Characteristics Refresh Table'

export interface ISwitchProductsCharacteristicsRefreshTable {
    type: typeof SWITCH_PRODUCTS_CHARACTERISTICS_REFRESH_TABLE
    payload: boolean
}

export const GET_CHARACTERISTIC_PRODUCTS = '[ PRODUCTS ] Get Characteristic Products'

export interface IGetCharacteristicProducts {
    type: typeof GET_CHARACTERISTIC_PRODUCTS
    payload: IGenericArrProduct[]
}

//productRecipe

export const PRODUCTS_RECIPES_CHANGE_MODAL_STATE = '[ PRODUCTS ] Products Recipes Change Modal State'

export interface IProductsRecipesChangeModalState {
    type: typeof PRODUCTS_RECIPES_CHANGE_MODAL_STATE
}

export const GET_PRODUCT_RECIPES_FOR_UPDATE = '[ PRODUCTS ] Get Product Recipes For Update'

export interface IGetProductsRecipesForUpdate {
    type: typeof GET_PRODUCT_RECIPES_FOR_UPDATE
    payload: IProductRecipes
    // DataIProducts
}

export const IS_PRODUCT_RECIPES_FOR_UPDATE = '[ PRODUCTS ] Is Product Recipes For Update'

export interface IIsProductsRecipesForUpdate {
    type: typeof IS_PRODUCT_RECIPES_FOR_UPDATE
    payload: boolean
}

export const SWITCH_PRODUCTS_RECIPES_REFRESH_TABLE = '[ PRODUCTS ] Switch Products Recipes Refresh Table'

export interface ISwitchProductsRecipesRefreshTable {
    type: typeof SWITCH_PRODUCTS_RECIPES_REFRESH_TABLE
    payload: boolean
}

export const GET_RECIPES_FOR_PRODUCTS = '[ PRODUCTS ] Get Recipes For Products'

export interface IGetRecipeProducts {
    type: typeof GET_RECIPES_FOR_PRODUCTS
    payload: IGenericArrProduct[]
}

//product-premium-characteristics

export const PRODUCTS_PREMIUM_CHARACTERISTICS_CHANGE_MODAL_STATE = '[ PRODUCTS ] Products Premium Characteristics Change Modal State'

export interface IProductsPremiumCharacteristicsChangeModalState {
    type: typeof PRODUCTS_PREMIUM_CHARACTERISTICS_CHANGE_MODAL_STATE
}

export const GET_PRODUCT_PREMIUM_CHARACTERISTICS_FOR_UPDATE = '[ PRODUCTS ] Get Product Premium Characteristics For Update'

export interface IGetProductsPremiumCharacteristicsForUpdate {
    type: typeof GET_PRODUCT_PREMIUM_CHARACTERISTICS_FOR_UPDATE
    payload: IProductPremiumCharacteristics
    // DataIProducts
}

export const IS_PRODUCT_PREMIUM_CHARACTERISTICS_FOR_UPDATE = '[ PRODUCTS ] Is Product Premium Characteristics For Update'

export interface IIsProductsPremiumCharacteristicsForUpdate {
    type: typeof IS_PRODUCT_PREMIUM_CHARACTERISTICS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_PRODUCTS_PREMIUM_CHARACTERISTICS_REFRESH_TABLE = '[ PRODUCTS ] Switch Products Premium Characteristics Refresh Table'

export interface ISwitchProductsPremiumCharacteristicsRefreshTable {
    type: typeof SWITCH_PRODUCTS_PREMIUM_CHARACTERISTICS_REFRESH_TABLE
    payload: boolean
}

//product_premium_preparation_steps

export const PRODUCT_PREMIUM_PREPARATION_STEPS_CHANGE_MODAL_STATE = '[ PRODUCTS ] Product Premium Preparation Steps Change Modal State'

export interface IProductPremiumPreparationStepsChangeModalState {
    type: typeof PRODUCT_PREMIUM_PREPARATION_STEPS_CHANGE_MODAL_STATE
}

export const GET_PRODUCT_PREMIUM_PREPARATION_STEPS_FOR_UPDATE = '[ PRODUCTS ] Get Product Premium Preparation Steps For Update'

export interface IGetProductPremiumPreparationStepsForUpdate {
    type: typeof GET_PRODUCT_PREMIUM_PREPARATION_STEPS_FOR_UPDATE
    payload: IProductPremiumPreparationSteps
    // DataIProducts
}

export const IS_PRODUCT_PREMIUM_PREPARATION_STEPS_FOR_UPDATE = '[ PRODUCTS ] Is Product Premium Preparation Steps For Update'

export interface IIsProductPremiumPreparationStepsForUpdate {
    type: typeof IS_PRODUCT_PREMIUM_PREPARATION_STEPS_FOR_UPDATE
    payload: boolean
}

export const SWITCH_PRODUCT_PREMIUM_PREPARATION_STEPS_REFRESH_TABLE = '[ PRODUCTS ] Switch Product Premium Preparation Steps Refresh Table'

export interface ISwitchProductPremiumPreparationStepsRefreshTable {
    type: typeof SWITCH_PRODUCT_PREMIUM_PREPARATION_STEPS_REFRESH_TABLE
    payload: boolean
}