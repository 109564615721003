import { TRootState } from "../@types/redux";

export const selectIsUpdateRecipe = (state: TRootState) =>
  state.recipes.isUpdateRecipes;

export const selectRecipeForUpdate = (state: TRootState) =>
  state.recipes.RecipesForUpdate;

export const selectRecipeRefreshTable = (state: TRootState) =>
  state.recipes.refreshTableRecipes;

export const selectModalRecipes = (state: TRootState) =>
  state.recipes.RecipesModalIsOpen;
