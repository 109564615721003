import { TRootState } from "../@types/redux";

export const selectModalCities = (state: TRootState) =>
  state.cities.citiesModalIsOpen;

export const selectIsUpdateCity = (state: TRootState) =>
  state.cities.isUpdateCity;

export const selectCityForUpdate = (state: TRootState) =>
  state.cities.citiesForUpdate;

export const selectCitiesRefreshTable = (state: TRootState) =>
  state.cities.refreshTableCities;

export const selectArrState = (state: TRootState) => state.cities.arrState;
export const selectArrCities = (state: TRootState) =>
  state.cities.citiesForUpdate;
