import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./auth.reducer";
import uiReducer from "./ui.reducer";
import userReducer from "./user.reducer";
import addressesUsersReducer from "./addressesUsers.reducer";
import productCharacteristicsReducer from "./productCharacteristics.reducer";
import productCategoriesReducer from "./productCategories.reducer";
import rankingsReducer from "./rankings.reducer";
import bannersReducer from "./banners.reducer";
import productsReducer from "./products.reducer";
import recipesReducer from "./recipes.reducer";
import ordersReducer from "./orders.reducer";
import taxReducer from "./tax.reducer";
import citiesReducer from "./cities.reducer";
import statesReducer from "./states.reducer";
import brandsReducer from "./brands.reducer";
import storesReducer from "./stores.reducer";
import discountCodeReducer from "./discountCode.reducer";
import contactsDirectoryReducer from "./contactsDirectory.reducer";
import directoryCategoriesReducer from "./directoryCategories.reducer";
import walletBonusReducer from "./walletBonus.reducer";
import walletMovementsReducer from "./walletMovements.reducer";
import coursesCategoriesReducer from "./coursesCategories.reducer";
import coursesReducer from "./courses.reducer";
import cMSReducer from "./cms.reducer";
import workOffersCategoriesReducer from "./workOffersCategories.reducer";
import workOffersReducer from "./workOffers.reducer";
import fulfillmentCompanyReducer from "./fulfillmentCompany.reducer";
import listCustomerGroupsReducer from "./listCustomerGroups.reducer";
import priceListReducer from "./priceList.reducer";
import systemVariablesReducer from "./systemVariables.reducer";
import configurationCashbackReducer from "./configurationCashback.reducer";

export default combineReducers({
  users: userReducer,
  auth: authReducer,
  ui: uiReducer,
  addressesUsers: addressesUsersReducer,
  productCharacteristics: productCharacteristicsReducer,
  productCategories: productCategoriesReducer,
  rankings: rankingsReducer,
  banners: bannersReducer,
  products: productsReducer,
  recipes: recipesReducer,
  orders: ordersReducer,
  tax: taxReducer,
  cities: citiesReducer,
  states: statesReducer,
  brands: brandsReducer,
  stores: storesReducer,
  discountCode: discountCodeReducer,
  contactsDirectory: contactsDirectoryReducer,
  directoryCategories: directoryCategoriesReducer,
  walletBonus: walletBonusReducer,
  walletMovements: walletMovementsReducer,
  coursesCategories: coursesCategoriesReducer,
  courses: coursesReducer,
  cms: cMSReducer,
  workOffersCategories: workOffersCategoriesReducer,
  workOffers: workOffersReducer,
  fulfillmentCompany: fulfillmentCompanyReducer,
  listCustomerGroups: listCustomerGroupsReducer,
  priceList: priceListReducer,
  systemVariables: systemVariablesReducer,
  configurationCashback: configurationCashbackReducer,
});
