import { TRootState } from '../@types/redux';

export const selectIsUpdateProduct= ( state: TRootState) =>state.products.isUpdateProducts

export const selectProductForUpdate= ( state: TRootState) =>state.products.productsForUpdate

export const selectProductRefreshTable= ( state: TRootState) =>state.products.refreshTableProducts

export const selectProductCategoriesArr= ( state: TRootState) =>state.products.arrCategories

export const selectProductBrandsArr= ( state: TRootState) =>state.products.arrBrands

export const selectProductTaxArr= ( state: TRootState) =>state.products.arrTax
//productCharacteristic
export const selectModalProductCharacteristic2= ( state: TRootState) =>state.products.productsCharacteristicModalIsOpen

export const selectIsUpdateProductCharacteristic2= ( state: TRootState) =>state.products.isUpdateProductCharacteristics

export const selectProductCharacteristicForUpdate2= ( state: TRootState) =>state.products.productCharacteristicsForUpdate

export const selectProductCharacteristicRefreshTable2= ( state: TRootState) =>state.products.refreshTableProductCharacteristics

export const selectProductCharacteristicsArr= ( state: TRootState) =>state.products.arrCharacteristic

//productRecipes
export const selectModalProductRecipes= ( state: TRootState) =>state.products.productsRecipeModalIsOpen

export const selectIsUpdateProductRecipes= ( state: TRootState) =>state.products.isUpdateProductsRecipes

export const selectProductRecipeForUpdate= ( state: TRootState) =>state.products.productsRecipesForUpdate

export const selectProductRecipeRefreshTable= ( state: TRootState) =>state.products.refreshTableProductsRecipes

export const selectProductRecipesArr= ( state: TRootState) =>state.products.arrRecipes

//product-premium-characteristics
export const selectModalProductPremiumCharacteristic= ( state: TRootState) =>state.products.ProductPremiumCharacteristicModalIsOpen

export const selectIsUpdateProductPremiumCharacteristic= ( state: TRootState) =>state.products.isUpdateProductPremiumCharacteristics

export const selectProductPremiumCharacteristicsForUpdate= ( state: TRootState) =>state.products.ProductPremiumCharacteristicsForUpdate

export const selectProductPremiumCharacteristicRefreshTable= ( state: TRootState) =>state.products.refreshTableProductPremiumCharacteristics

//product_premium_preparation_steps
export const selectModalProductPremiumPreparationSteps= ( state: TRootState) =>state.products.productPremiumPreparationStepsModalIsOpen

export const selectIsUpdateProductPremiumPreparationSteps= ( state: TRootState) =>state.products.isUpdateProductPremiumPreparationSteps

export const selectProductPremiumPreparationStepsForUpdate= ( state: TRootState) =>state.products.productPremiumPreparationStepsForUpdate

export const selectProductPremiumPreparationStepsRefreshTable= ( state: TRootState) =>state.products.refreshTableProductPremiumPreparationSteps