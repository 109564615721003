// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { ISwitchProductPremiumPreparationStepsRefreshTable } from './../action-types/products-action.types';

import { brandsService, productCategoriesService, productCharacteristics2Service, productCharacteristicsService, productsPremiumCharacteristicsService, productsPremiumPreparationsStepsService, productsRecipesService, productsService, recipesService, taxRuleService } from "../../shared/services";

import { productsActions } from "../action-types";
import { IGenericArrProduct, IProductCharacteristics2, IProductRecipes, IProducts, IProductPremiumCharacteristics, IProductPremiumPreparationSteps } from '../@types/products';

export const setProductsForUpdate = (val: IProducts): productsActions. IGetProductsForUpdate => ({
    type: productsActions.GET_PRODUCT_FOR_UPDATE,
    payload: val
})

export const setIsProductsForUpdate = (val: boolean): productsActions.IIsProductsForUpdate =>({
    type: productsActions.IS_PRODUCT_FOR_UPDATE,
    payload: val
})

export const createProduct = async(data: Partial<IProducts>) => {
    return productsService.create(data)
    .then((res: IProducts) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateProduct = async(data:Partial<IProducts>) => {
    return await productsService.patch(data?.id!, data)
    .then((res: IProducts) => {
        // console.log({res});
        return res
    })
    .catch((err: any) => { return err.message })
}

export const deleteProduct = async (id: number ) =>{
    return  await productsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableProducts =( val: boolean ): productsActions.ISwitchProductsRefreshTable=>({
    type: productsActions.SWITCH_PRODUCTS_REFRESH_TABLE,
    payload: val
})

export const getCategories = async () => {
    return await productCategoriesService.find({
        query:{
            $select: ["id","name","parent_id"],
            $client: {tree: true},
        },
    })
    .then((res)=>{ return res.data})
    .catch((err: any) => { return err.message })
}

export const setCategoriesArr = (val:IGenericArrProduct[]): productsActions.IGetCategoriesProducts =>({
    type: productsActions.GET_CATEGORIES_PRODUCTS,
    payload: val
})

export const getBrands = async () => {
    return await brandsService.find({
        query:{
            $select: ["id","name"],
            $limit: 9999999999999,
        },
    })
    .then((res)=>{ return res.data})
    .catch((err: any) => { return err.message })
}

export const setBrandsArr = (val:IGenericArrProduct[]): productsActions.IGetBrandsProducts =>({
    type: productsActions.GET_BRANDS_PRODUCTS,
    payload: val
})

export const getTaxArr = async () => {
    return await taxRuleService.find({
        query:{
            $select: ["id","name"],
            $limit: 9999999999999,
        },
    })
    .then((res)=>{ return res.data})
    .catch((err: any) => { return err.message })
}

export const setTaxArr = (val:IGenericArrProduct[]): productsActions.IGetTaxProducts =>({
    type: productsActions.GET_TAX_PRODUCTS,
    payload: val
})

//ProductCharacteristic

export const productsCharacteristicChangeModalState = (): productsActions.IProductsCharacteristicsChangeModalState => ({
    type: productsActions.PRODUCTS_CHARACTERISTICS_CHANGE_MODAL_STATE
})

export const setProductsCharacteristicForUpdate = (val: IProductCharacteristics2): productsActions.IGetProductsCharacteristicsForUpdate => ({
    type: productsActions.GET_PRODUCT_CHARACTERISTICS_FOR_UPDATE,
    payload: val
})

export const setIsProductsCharacteristicForUpdate = (val: boolean): productsActions.IIsProductsCharacteristicsForUpdate =>({
    type: productsActions.IS_PRODUCT_CHARACTERISTICS_FOR_UPDATE,
    payload: val
})

export const createProductCharacteristic2 = async(data: Partial<IProductCharacteristics2>) => {
    return productCharacteristics2Service.create(data)
    .then((res: IProductCharacteristics2) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateProductCharacteristic2 = async(data:Partial<IProductCharacteristics2>) => {
    return await productCharacteristics2Service.patch(data?.id!, data)
    .then((res: IProductCharacteristics2) => {
        // console.log({res});
        return res
    })
    .catch((err: any) => { return err.message })
}

export const deleteProductCharacteristic2 = async (id: number ) =>{
    return  await productCharacteristics2Service.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableProductsCharacteristics =( val: boolean ): productsActions.ISwitchProductsCharacteristicsRefreshTable=>({
    type: productsActions.SWITCH_PRODUCTS_CHARACTERISTICS_REFRESH_TABLE,
    payload: val
})

export const getCharacteristicArr = async () => {
    return await productCharacteristicsService.find({
        query:{
            $select: ["id","name"],
            $limit: 9999999,
        },
    })
    .then((res)=>{ return res.data})
    .catch((err: any) => { return err.message })
}

export const setCharacteristicArr = (val:IGenericArrProduct[]): productsActions.IGetCharacteristicProducts =>({
    type: productsActions.GET_CHARACTERISTIC_PRODUCTS,
    payload: val
})

//ProductRecipes

export const productsRecipeChangeModalState = (): productsActions.IProductsRecipesChangeModalState => ({
    type: productsActions.PRODUCTS_RECIPES_CHANGE_MODAL_STATE
})

export const setProductsRecipeForUpdate = (val: IProductRecipes): productsActions.IGetProductsRecipesForUpdate => ({
    type: productsActions.GET_PRODUCT_RECIPES_FOR_UPDATE,
    payload: val
})

export const setIsProductsRecipeForUpdate = (val: boolean): productsActions.IIsProductsRecipesForUpdate =>({
    type: productsActions.IS_PRODUCT_RECIPES_FOR_UPDATE,
    payload: val
})

export const createProductRecipe = async(data: Partial<IProductRecipes>) => {
    return productsRecipesService.create(data)
    .then((res: IProductRecipe) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateProductRecipe = async(data:Partial<IProductRecipes>) => {
    return await productsRecipesService.patch(data?.id!, data)
    .then((res: IProductRecipe) => {
        return res
    })
    .catch((err: any) => { return err.message })
}

export const deleteProductRecipe = async (id: number ) =>{
    return  await productsRecipesService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableProductsRecipe =( val: boolean ): productsActions.ISwitchProductsCharacteristicsRefreshTable=>({
    type: productsActions.SWITCH_PRODUCTS_RECIPES_REFRESH_TABLE,
    payload: val
})

export const getRecipesArr = async () => {
    return await recipesService.find({
        query:{
            status: 'active',
            $select: ["id","name"],
            $limit: 9999999,
        },
    })
    .then((res)=>{ return res.data})
    .catch((err: any) => { return err.message })
}

export const setRecipesArr = (val:IGenericArrProduct[]): productsActions.IGetCharacteristicProducts =>({
    type: productsActions.GET_RECIPES_FOR_PRODUCTS,
    payload: val
})

//product-premium-characteristics

export const ProductsPremiumCharacteristicChangeModalState = (): productsActions.IProductsPremiumCharacteristicsChangeModalState => ({
    type: productsActions.PRODUCTS_PREMIUM_CHARACTERISTICS_CHANGE_MODAL_STATE
})

export const setProductsPremiumCharacteristicForUpdate = (val: IProductPremiumCharacteristics): productsActions.IGetProductsPremiumCharacteristicsForUpdate => ({
    type: productsActions.GET_PRODUCT_PREMIUM_CHARACTERISTICS_FOR_UPDATE,
    payload: val
})

export const setIsProductsPremiumCharacteristicForUpdate = (val: boolean): productsActions.IIsProductsPremiumCharacteristicsForUpdate =>({
    type: productsActions.IS_PRODUCT_PREMIUM_CHARACTERISTICS_FOR_UPDATE,
    payload: val
})

export const createProductPremiumCharacteristic = async(data: Partial<IProductPremiumCharacteristics>) => {
    return productsPremiumCharacteristicsService.create(data)
    .then((res: IProductPremiumCharacteristics) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateProductPremiumCharacteristic = async(data:Partial<IProductPremiumCharacteristics>) => {
    return await productsPremiumCharacteristicsService.patch(data?.id!, data)
    .then((res: IProductPremiumCharacteristics) => {
        return res
    })
    .catch((err: any) => { return err.message })
}

export const deleteProductPremiumCharacteristic = async (id: number ) =>{
    return  await productsPremiumCharacteristicsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableProductsPremiumCharacteristic =( val: boolean ): productsActions.ISwitchProductsPremiumCharacteristicsRefreshTable=>({
    type: productsActions.SWITCH_PRODUCTS_PREMIUM_CHARACTERISTICS_REFRESH_TABLE,
    payload: val
})

//product_premium_preparation_steps

export const ProductsPremiumPreparationStepsChangeModalState = (): productsActions.IProductPremiumPreparationStepsChangeModalState => ({
    type: productsActions.PRODUCT_PREMIUM_PREPARATION_STEPS_CHANGE_MODAL_STATE
})

export const setProductsPremiumPreparationStepsForUpdate = (val: IProductPremiumPreparationSteps): productsActions.IGetProductPremiumPreparationStepsForUpdate => ({
    type: productsActions.GET_PRODUCT_PREMIUM_PREPARATION_STEPS_FOR_UPDATE,
    payload: val
})

export const setIsProductsPremiumPreparationStepsForUpdate = (val: boolean): productsActions.IIsProductPremiumPreparationStepsForUpdate =>({
    type: productsActions.IS_PRODUCT_PREMIUM_PREPARATION_STEPS_FOR_UPDATE,
    payload: val
})

export const createProductPremiumPreparationSteps = async(data: Partial<IProductPremiumPreparationSteps>) => {
    return productsPremiumPreparationsStepsService.create(data)
    .then((res: IProductPremiumPreparationSteps) => {return res})
    .catch((err: any) => { return err.message })
}

export const updateProductPremiumPreparationSteps = async(data:Partial<IProductPremiumPreparationSteps>) => {
    return await productsPremiumPreparationsStepsService.patch(data?.id!, data)
    .then((res: IProductPremiumPreparationSteps) => {
        return res
    })
    .catch((err: any) => { return err.message })
}

export const deleteProductPremiumPreparationSteps = async (id: number ) =>{
    return  await productsPremiumPreparationsStepsService.remove(id.toString())
    .then((res: any) => {return res})
    .catch((err: any) => { return err.message })
}

export const refreshTableProductsPremiumPreparationSteps =( val: boolean ): productsActions.ISwitchProductPremiumPreparationStepsRefreshTable=>({
    type: productsActions.SWITCH_PRODUCT_PREMIUM_PREPARATION_STEPS_REFRESH_TABLE,
    payload: val
})