import * as yup from 'yup'

export const validateUpdateRecipes = yup.object().shape({
    name:yup
    .string()
    .min(2, 'El NOMBRE debe tener más de 2 caracteres'),
    category_id:yup
    .string(),
    path_cover:yup
    .string()
    .min(2, 'LA IMAGEN debe tener más de 2 caracteres'),
    short_description: yup
    .string()
    .nullable()
    .min(2, 'LA DESCRIPCIÓN CORTA debe tener más de 2 caracteres'),
    description:yup
    .string()
    .nullable()
    .min(2, 'LA DESCRIPCIÓN debe tener más de 2 caracteres'),
    rating_score:yup
    .number()
    .positive()
    .typeError('Campo requerido.')
    .min(0, 'Debe de utilizar un numero que no sea negativo'),
    user_id:yup
    .string()
    .nullable()
    ,
    html_ingredients: yup
    .string()
    .nullable()
    .min(2, 'LOS INGREDIENTES debe tener más de 2 caracteres'),
    html_steps: yup
    .string()
    .nullable()
    .min(2, 'LOS PASOS debe tener más de 2 caracteres'),
    status:yup
    .string()
    .oneOf(['active', 'inactive'], 'Debes elegir entre estas opciones'),
})