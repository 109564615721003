import { IRecipes } from "../@types";

export const GET_RECIPE_FOR_UPDATE = "[ RECIPES ] Get RECIPE For Update";

export interface IGetRecipesForUpdate {
  type: typeof GET_RECIPE_FOR_UPDATE;
  payload: IRecipes;
  // DataIRecipes
}

export interface IRecipesModalState {
  type: typeof RECIPES_MODAL_STATE;
}

export const RECIPES_MODAL_STATE = "[ StoreS ] Stores Modal State";

export const IS_RECIPE_FOR_UPDATE = "[ RECIPES ] Is RECIPE For Update";

export interface IIsRecipesForUpdate {
  type: typeof IS_RECIPE_FOR_UPDATE;
  payload: boolean;
}

export const SWITCH_RECIPES_REFRESH_TABLE =
  "[ RECIPES ] Switch RECIPEs Refresh Table";

export interface ISwitchRecipesRefreshTable {
  type: typeof SWITCH_RECIPES_REFRESH_TABLE;
  payload: boolean;
}

export const GET_CATEGORIES_RECIPES = "[ RECIPES ] Get Categories RECIPEs";
