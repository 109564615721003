import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useProductPremiumPreparationSteps } from "./useProductPremiumPreparationSteps";
import { deleteProductPremiumPreparationSteps, ProductsPremiumPreparationStepsChangeModalState, refreshTableProductsPremiumPreparationSteps, setIsProductsPremiumPreparationStepsForUpdate, setProductsPremiumPreparationStepsForUpdate } from "../../../../redux";

const { confirm } = Modal;

export const useTableProductPremiumPreparationSteps = () => {
    const dispatch = useAppDispatch();
    const { tableProps } = useProductPremiumPreparationSteps();

    const changeProductPremiumPreparationStepsForUpdate = (user: any) => {
        dispatch(setIsProductsPremiumPreparationStepsForUpdate(true));
        dispatch(setProductsPremiumPreparationStepsForUpdate(user));
        dispatch(ProductsPremiumPreparationStepsChangeModalState());
    };

    const showDeleteConfirm = (valId: number, val: string) => {
        confirm({
          title: `Estás seguro de borrar los pasos de preparación asociada al producto con el id: ${valId}`,
          icon: <ExclamationCircleOutlined />,
          content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar pasos" en caso tal no queras borrar nada solo oprime en "Cancelar".`,
          okText: "Borrar pasos",
          okType: "danger",
          cancelText: "Cancelar",
          keyboard: true,
          maskClosable: true,
          async onOk() {
            await deleteProductPremiumPreparationSteps(valId)
              .then(() => {
                dispatch(refreshTableProductsPremiumPreparationSteps(true));
                message.success(`Se ha borrado los pasos de preparación asociada al producto con id:${valId}`);
                tableProps?.refresh();
              })
              .catch((err) => {
                console.log({ err });
                message.error(err.message);
              });
          },
          onCancel() {},
        });
    };

    return {
        //state
        //methods
        //functions
        changeProductPremiumPreparationStepsForUpdate,
        showDeleteConfirm,
      };
  }
  