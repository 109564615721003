import { useEffect } from "react";
import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { deleteProductRecipe, getRecipesArr, productsRecipeChangeModalState, refreshTableProductsRecipe, selectProductRecipesArr, setIsProductsRecipeForUpdate, setProductsRecipeForUpdate, setRecipesArr } from "../../../../redux";
import { useProductRecipes } from "./useProductRecipes";


const { confirm } = Modal;

export const useTableProductsRecipes = () => {
    const dispatch = useAppDispatch();
    const arrRecipes = useSelector(selectProductRecipesArr);
    const { tableProps } = useProductRecipes();
  
    const getServicesProductRecipes = async () => {
      await getRecipesArr()
        .then((res) => dispatch(setRecipesArr(res)))
        .catch((res) => {
          alert({ res });
          dispatch(setRecipesArr([]));
        });
    };
  
    useEffect(() => {
      if (arrRecipes.length === 0) {
        getServicesProductRecipes();
      }
    }, [arrRecipes]);
  
    const changeProductRecipeForUpdate = (user: any) => {
      dispatch(setIsProductsRecipeForUpdate(true));
      dispatch(setProductsRecipeForUpdate(user));
      dispatch(productsRecipeChangeModalState());
    };
  
    const showDeleteConfirm = (valId: number, val: string) => {
      confirm({
        title: `Estás seguro de borrar la receta asociada al producto con el id: ${valId}`,
        icon: <ExclamationCircleOutlined />,
        content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar receta" en caso tal no queras borrar nada solo oprime en "Cancelar".`,
        okText: "Borrar receta",
        okType: "danger",
        cancelText: "Cancelar",
        keyboard: true,
        maskClosable: true,
        async onOk() {
          await deleteProductRecipe(valId)
            .then(() => {
              dispatch(refreshTableProductsRecipe(true));
              message.success(`Se ha borrado la receta asociada al producto con id:${valId}`);
              tableProps?.refresh();
            })
            .catch((err) => {
              console.log({ err });
              message.error(err.message);
            });
        },
        onCancel() {},
      });
    };
  
    return {
      //state
      arrRecipes,
      //methods
      //functions
      changeProductRecipeForUpdate,
      showDeleteConfirm,
    };
}
