import { Button, Col, Row, Typography } from "antd";
import { FormProvider } from "react-hook-form";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { TableColumns } from "../../../../shared/components/ui/Table/types";
import Table from "../../../../shared/components/ui/Table";
import { selectModalProductPremiumCharacteristic } from "../../../../redux";
import { useProductPremiumCharacteristics, useTableProductPremiumCharacteristics } from "../../hooks";
import { IProductPremiumCharacteristics } from "../../../../redux/@types";
import { productsPremiumCharacteristicsService } from "../../../../shared/services";
import { ModalProductPremiumCharacteristics } from "../../../../shared/components";

const { Title } = Typography;

export const TableProductPremiumCharacteristics = () => {
    const params = useParams();

    const modalProductPremiumCharacteristics = useSelector(
        selectModalProductPremiumCharacteristic
    );
    const { changeProductPremiumCharacteristicForUpdate, showDeleteConfirm } = useTableProductPremiumCharacteristics();
    const {
        isUpdateProductPremiumCharacteristic,
        setTableProps,
        navigateProductPremiumCharacteristicCreate,
        formMethodsCreate,
        formMethodsUpdate,
    } = useProductPremiumCharacteristics();

    const columns: TableColumns<IProductPremiumCharacteristics> = [
        {
          title: "ID",
          width: 5,
          dataIndex: "id",
          key: "id",
          filterType: "filterByID",
        },
        {
          title: "Descripción",
          width: 7,
          dataIndex: 'html_description',
        //   key: "recipe_id",
            render: (_, item) => {
                return (
                  <Col
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                    }}
                  >
                    <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
                      {item.html_description.length > 120 ? item.html_description.substring(0, 120) + "..." : item.html_description}
                    </Title>
                  </Col>
                );
              },
            // filterType: "text",
        },
        {
          title: "Acciones",
          key: "operation",
          fixed: "right",
          width: 2,
          render: (_, item) => {
            return (
              <Col
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                }}
              >
                <Button
                  type="primary"
                  shape="circle"
                  onClick={() => changeProductPremiumCharacteristicForUpdate(item)}
                  icon={<EditOutlined />}
                  size="large"
                />
                <Button
                  type="primary"
                  danger
                  onClick={() =>
                    showDeleteConfirm(item.id, `${item.product_id}`)
                  }
                  shape="circle"
                  icon={<DeleteOutlined />}
                  size="large"
                />
              </Col>
            );
          },
        },
    ];

  return (
    <Row justify="start">
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <Button
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
          size="large"
          onClick={navigateProductPremiumCharacteristicCreate}
        >
          Agregar nueva característica
        </Button>
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Table<IProductPremiumCharacteristics>
          columns={columns}
        //   scroll={{ x: 'max-content' }}
          fetchQueryProps={{
            product_id: params.id,
            $sort: { id: -1 },
          }}
          service={productsPremiumCharacteristicsService}
          onLoad={setTableProps}
        />
    </Col>
    {modalProductPremiumCharacteristics && isUpdateProductPremiumCharacteristic && (
        <FormProvider {...formMethodsUpdate}>
            <ModalProductPremiumCharacteristics />
        </FormProvider>
    )}
    {modalProductPremiumCharacteristics && !isUpdateProductPremiumCharacteristic && (
        <FormProvider {...formMethodsCreate}>
            <ModalProductPremiumCharacteristics />
        </FormProvider>
    )}
    </Row>
  )
}
