import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { getBrands, getCategories, getTaxArr, selectProductBrandsArr, selectProductCategoriesArr, selectProductTaxArr, setBrandsArr, setCategoriesArr, setTaxArr } from "../../../redux";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";

export interface PropsArrCategoriesRes {
  id:        number;
  name:      string;
  parent_id: number;
  path:      string;
  children:  PropsArrCategoriesRes[];
}

export const useProductModal = () => {
    const dispatch = useAppDispatch()
    const [arrCategories, setArrCategories] = useState<[] | PropsArrCategoriesRes[]>([])

    // const arrCategories = useSelector(selectProductCategoriesArr);
    const arrBrands = useSelector(selectProductBrandsArr);
    const arrTax = useSelector(selectProductTaxArr);

    const getServicesProduct = async() =>{
      await getCategories()
      .then((res)=> {
        // console.log({res})
        // dispatch(setCategoriesArr(res))
        setArrCategories(res)
      })
      .catch((res) => {
        alert({res})
        // dispatch(setCategoriesArr([]))
        setArrCategories([])
      })
      await getBrands()
      .then((res)=> dispatch(setBrandsArr(res)))
      .catch((res) => {
        alert({res})
        dispatch(setCategoriesArr([]))
      })
      await getTaxArr()
      .then((res)=> dispatch(setTaxArr(res)))
      .catch((res) => {
        alert({res})
        dispatch(setCategoriesArr([]))
      })
  }

    useEffect(() => {
        // if(arrBrands.length === 0 && arrCategories.length === 0){
          getServicesProduct()
        // }
    }, [])

  return {
    arrCategories,
    arrBrands,
    arrTax,
  }
}
