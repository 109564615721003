import { Button, Col, Typography } from "antd";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { useProductRecipes, useTableProductsRecipes, WrapperModalProducts } from "../../../../modules/products";

import { selectIsUpdateProductRecipes, selectModalProductRecipes, selectProductRecipeForUpdate } from "../../../../redux";
import { IProductRecipes } from "../../../../redux/@types";
import { InputSelect } from "../inputs";

const { Title } = Typography;

export const ModalProductRecipes = () => {
    const modalProductRecipe = useSelector(selectModalProductRecipes);
    const isUpdateProductRecipe = useSelector(selectIsUpdateProductRecipes);
    const updateProductRecipe = useSelector(selectProductRecipeForUpdate);

    const {
        isLoading,
        onSubmitCreateOrUpdate,
        navigateProductRecipeCreate,
    } = useProductRecipes();
    const { arrRecipes } = useTableProductsRecipes();

    const { control, handleSubmit: onSubmit } = useFormContext<IProductRecipes>();

  return (
    <WrapperModalProducts
    title={
      isUpdateProductRecipe
        ? `Actualizando receta de producto: '${updateProductRecipe?.id!}'`
        : `Creando receta de producto`
    }
    open={modalProductRecipe}
    width={"320px"}
    destroyOnClose
    onClose={navigateProductRecipeCreate}
  >
    <Col
      span={24}
      style={{
        display: "flex",
        marginTop: "10px",
        padding: 5,
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
      <Title style={{ marginBottom: "1.5px" }} level={5}>
        Receta:
      </Title>
      <InputSelect
        name="recipe_id"
        control={control}
        dataOptions={arrRecipes.map((item) => ({
          title: item.name,
          value: item.id.toString(),
        }))}
        label="Receta principal"
      />
    </Col>
    <Button
      style={{
        width: "100%",
        marginTop: 10,
      }}
      type="primary"
      shape="round"
      size="large"
      onClick={onSubmit(onSubmitCreateOrUpdate)}
      loading={isLoading}
    >
      {isUpdateProductRecipe
        ? "Actualizar información"
        : "Crear receta de producto"}
    </Button>
  </WrapperModalProducts>
  )
}
