import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  StoresChangeModalState,
  deleteStore,
  refreshTableStores,
  setStoreForUpdate,
  setIsStoreForUpdate,
} from "../../../redux";
import { DataStores } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useStores } from "./useStores";

const { confirm } = Modal;

export const useTableStores = () => {
  const dispatch = useAppDispatch();
  const { tableProps } = useStores();

  const changeStoreForUpdate = (val: DataStores) => {
    dispatch(setIsStoreForUpdate(true));
    dispatch(setStoreForUpdate(val));
    dispatch(StoresChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar la Sede con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar Sede" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar sede",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        await deleteStore(valId)
          .then((res) => {
            dispatch(refreshTableStores(true));
            if(res[0].name){
              message.success(`Se ha borrado con éxito la Sede con id:${valId}`);
            }else if(res){
              console.log({res})
              message.error(`${res}`);
            }
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    //methods
    //functions
    changeStoreForUpdate,
    showDeleteConfirm,
  };
};
