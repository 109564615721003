import { ContentTranslatedKey, TableCounterProducts, TableProduct, ValForTable } from "./styled";

interface PropsArray {
  [k: string]: number;
}
interface Props {
  counterArr: [] | PropsArray[]
  getUpdateQuerySelector: (val: string) => void
  querySelector: string[]
}

const translations = {
  total_without_categories: 'Sin categorías',
  total_without_images: 'Sin imágenes',
  total: 'Total',
  total_active: 'Activos',
  total_inactive: 'Inactivos',
  total_with_out_description_short: 'Sin descripción corta',
  total_with_out_weight: 'Sin peso',
  total_with_out_height: 'Sin altura',
  total_with_out_width: 'Sin ancho',
  total_with_out_long: 'Sin longitud'
};

export const CounterProductsTable = ({ counterArr, getUpdateQuerySelector, querySelector }:Props) => {
  return (
    <>
      {counterArr && counterArr.length === 10 && (
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column', padding: 0}}>
        <TableProduct>
          {counterArr.sort((a, b) => {
            if (Object.keys(a)[0] === "total") {
              return 1;
            }
            if (Object.keys(b)[0] === "total") {
              return -1;
            }
            return 0;}).map((item, idx) => {
            const [key, value] = Object.entries(item)[0];
            const translatedKey = translations[key as keyof typeof translations];
            return (
              <TableCounterProducts style={!querySelector.includes(key) ? {} : { backgroundColor: "#570010" } } onClick={()=> {
                if(key!== 'total')getUpdateQuerySelector(key)
                }} key={idx}>
                <ContentTranslatedKey style={!querySelector.includes(key) ? {} : {backgroundColor: "#570010", color: "#FFF" } }>{translatedKey}</ContentTranslatedKey>
                <ValForTable><h4 style={!querySelector.includes(key) ? {} : { color: "#FFF" } }>{value}</h4></ValForTable>
              </TableCounterProducts>
            );
          })}
        </TableProduct>
        </div>
      )}
    </>
  );
}
