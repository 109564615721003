import { useState, useEffect } from 'react';
import { message } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import useAppDispatch from '../../../../redux/hooks/useAppDispatch';
import { TableProperties } from '../../../../shared/components/ui/Table/types';
import { createProductPremiumPreparationSteps, ProductsPremiumPreparationStepsChangeModalState, refreshTableProductsPremiumPreparationSteps, selectIsUpdateProductPremiumPreparationSteps, selectModalProductPremiumPreparationSteps, selectProductForUpdate, selectProductPremiumPreparationStepsForUpdate, selectProductPremiumPreparationStepsRefreshTable, setIsProductsPremiumPreparationStepsForUpdate, updateProductPremiumPreparationSteps } from '../../../../redux';
import { IProductPremiumPreparationSteps } from '../../../../redux/@types';

export const useProductPremiumPreparationSteps = () => {
    const dispatch = useAppDispatch();
    const isUpdateProductPremiumPreparationSteps = useSelector(
        selectIsUpdateProductPremiumPreparationSteps
    );
    const dataUpdateProductPremiumPreparationSteps = useSelector(
        selectProductPremiumPreparationStepsForUpdate
    );
    const isRefreshTableProductPremiumPreparationSteps = useSelector(
        selectProductPremiumPreparationStepsRefreshTable
    );
    const dataProduct = useSelector(selectProductForUpdate);

    const navigateProductPremiumPreparationStepsCreate = () => {
        dispatch(setIsProductsPremiumPreparationStepsForUpdate(false));
        dispatch(ProductsPremiumPreparationStepsChangeModalState())
    };

    const [isLoading, setIsLoading] = useState(false);
    const [tableProps, setTableProps] = useState<TableProperties>();

    const formMethodsCreate = useForm<IProductPremiumPreparationSteps>({
        //resolver: yupResolver(validateCreateProductPremiumPreparationSteps),
    });

    const formMethodsUpdate = useForm<IProductPremiumPreparationSteps>({
        //resolver: yupResolver(validateUpdateProductPremiumPreparationSteps),
    });
    useEffect(() => {
        if (isUpdateProductPremiumPreparationSteps) {
            formMethodsCreate.reset();
            formMethodsUpdate.reset({ ...dataUpdateProductPremiumPreparationSteps });
        }

        return () => {
            formMethodsUpdate.reset();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUpdateProductPremiumPreparationSteps, dataUpdateProductPremiumPreparationSteps]);

    const onSubmitCreateOrUpdate = async (data: IProductPremiumPreparationSteps) => {
        // console.log({ data });
        if (isLoading) return;
        setIsLoading(true);
        if (isUpdateProductPremiumPreparationSteps) {
          //console.log("Actualiando===>", data);
          await updateProductPremiumPreparationSteps({
            id: data.id,
            html_description: data.html_description,
            step_number: data.step_number,
            product_id: dataProduct?.id!,
          })
            .then((res: Partial<IProductPremiumPreparationSteps>) => {
              setIsLoading(false);
              // dispatch(mappingUpdatedUser(res))
              if (res.id) {
                dispatch(ProductsPremiumPreparationStepsChangeModalState())
                message.success(`Se ha actualizado con éxito los pasos de preparación con id:${res.id}`)
                dispatch(refreshTableProductsPremiumPreparationSteps(true));
              } else {
                message.error(res as string);
              }
            })
            .catch((err: any) => {
              setIsLoading(false);
              console.log(err);
              message.error(err.message);
            });
        } else {
          // console.log('Creando===>',data)
          await createProductPremiumPreparationSteps({
            html_description: data.html_description,
            step_number: data.step_number,
            product_id: dataProduct?.id!,
          })
            .then((res: any) => {
              setIsLoading(false);
              if (res.id) {
                dispatch(ProductsPremiumPreparationStepsChangeModalState())
                message.success(
                  `Se ha creado con éxito los pasos de preparación con id:${res.id}`
                );
                dispatch(refreshTableProductsPremiumPreparationSteps(true));
              } else {
                message.error(res as any);
              }
            })
            .catch((err: any) => {
              setIsLoading(false);
              console.log(err);
              message.error(err.message);
            });
        }
    }

    useEffect(() => {
        if (isRefreshTableProductPremiumPreparationSteps) {
            tableProps?.refresh();
            dispatch(refreshTableProductsPremiumPreparationSteps(false));
        }
    }, [isRefreshTableProductPremiumPreparationSteps]);
  return {
    //state
    isUpdateProductPremiumPreparationSteps,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    navigateProductPremiumPreparationStepsCreate,
  }
}
