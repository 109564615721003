import { DataStores, IStoresState } from "../@types";
import { storesActions } from "../action-types";

type StoresActionType =
  | { type: typeof storesActions.STORES_MODAL_STATE }
  | { type: typeof storesActions.GET_STORE_FOR_UPDATE; payload: DataStores }
  | { type: typeof storesActions.IS_STORES_FOR_UPDATE; payload: boolean }
  | {
      type: typeof storesActions.SWITCH_STORES_REFRESH_TABLE;
      payload: boolean;
    };

const INITIAL_STATE: IStoresState = {
  StoresForUpdate: undefined,
  isUpdateStore: false,
  StoresModalIsOpen: false,
  refreshTableStores: false,
};

export default function StoresReducer(
  state: IStoresState = INITIAL_STATE,
  action: StoresActionType
): IStoresState {
  switch (action.type) {
    case storesActions.STORES_MODAL_STATE:
      return {
        ...state,
        StoresModalIsOpen: !state.StoresModalIsOpen,
      };
    case storesActions.GET_STORE_FOR_UPDATE:
      return {
        ...state,
        StoresForUpdate: action.payload,
      };
    case storesActions.IS_STORES_FOR_UPDATE:
      return {
        ...state,
        isUpdateStore: action.payload,
      };
    case storesActions.SWITCH_STORES_REFRESH_TABLE:
      return {
        ...state,
        refreshTableStores: action.payload,
      };
    default:
      return state;
  }
}
