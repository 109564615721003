import * as yup from "yup";

export const validateCreateStores = yup.object().shape({
  name: yup
    .string()
    .required("Campo requerido.")
    .min(2, "El NOMBRE debe tener más de 2 caracteres"),
  email: yup
    .string()
    .required("Campo requerido.")
    .min(2, "El correo debe tener más de 2 caracteres"),
  schedule: yup
    .string()
    .required("Campo requerido.")
    .min(2, "LA DIRECCION debe tener más de 2 caracteres"),
  address: yup
    .string()
    .required("Campo requerido.")
    .min(2, "LA DIRECCION debe tener más de 2 caracteres"),
  phone: yup
    .string()
    .typeError("Campo requerido.")
    .min(0, "Debe de utilizar un numero que no sea negativo"),
});
