import { DataStores } from "../@types";

export const STORES_MODAL_STATE = "[ StoreS ] Stores Modal State";

export interface IStoresModalState {
  type: typeof STORES_MODAL_STATE;
}

export const GET_STORE_FOR_UPDATE = "[ StoreS ] Get Store For Update";

export interface IGetStoreForUpdate {
  type: typeof GET_STORE_FOR_UPDATE;
  payload: DataStores;
}

export const IS_STORES_FOR_UPDATE = "[ StoreS ] Is Store For Update";

export interface IIsStoreForUpdate {
  type: typeof IS_STORES_FOR_UPDATE;
  payload: boolean;
}

export const SWITCH_STORES_REFRESH_TABLE =
  "[ StoreS ] Switch Stores Refresh Table";

export interface ISwitchStoresRefreshTable {
  type: typeof SWITCH_STORES_REFRESH_TABLE;
  payload: boolean;
}
