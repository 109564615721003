import { storesService } from "../../shared/services";
import { DataStores } from "../@types";
import { storesActions } from "../action-types";

export const StoresChangeModalState = (): storesActions.IStoresModalState => ({
  type: storesActions.STORES_MODAL_STATE,
});

export const setStoreForUpdate = (
  val: DataStores
): storesActions.IGetStoreForUpdate => ({
  type: storesActions.GET_STORE_FOR_UPDATE,
  payload: val,
});

export const setIsStoreForUpdate = (
  val: boolean
): storesActions.IIsStoreForUpdate => ({
  type: storesActions.IS_STORES_FOR_UPDATE,
  payload: val,
});

export const createStore = async (data: Partial<DataStores>) => {
  return await storesService
    .create(data)
    .then((res: DataStores) => {
      return res;
    })
    .catch((err: any) => {
      return err.message;
    });
};

export const updateStore = async (data: Partial<DataStores>) => {
  return await storesService
    .patch(data?.id!, data)
    .then((res: DataStores) => {
      return res;
    })
    .catch((err: any) => {
      return err.message;
    });
};

export const deleteStore = async (id: number) => {
  return await storesService
    .remove(id.toString())
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      return err.message;
    });
};

export const refreshTableStores = (
  val: boolean
): storesActions.ISwitchStoresRefreshTable => ({
  type: storesActions.SWITCH_STORES_REFRESH_TABLE,
  payload: val,
});
