// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { recipesService } from "../../shared/services";

import { RecipesActions } from "../action-types";
import {
  IGenericArrRecipe,
  IRecipes,
  IRecipePremiumCharacteristics,
  IRecipePremiumPreparationSteps,
} from "../@types/recipes";

export const setRecipesForUpdate = (
  val: IRecipes
): RecipesActions.IGetRecipesForUpdate => ({
  type: RecipesActions.GET_RECIPE_FOR_UPDATE,
  payload: val,
});

export const setIsRecipesForUpdate = (
  val: boolean
): RecipesActions.IIsRecipesForUpdate => ({
  type: RecipesActions.IS_RECIPE_FOR_UPDATE,
  payload: val,
});

export const createRecipe = async (data: Partial<IRecipes>) => {
  return recipesService
    .create(data)
    .then((res: IRecipes) => {
      return res;
    })
    .catch((err: any) => {
      return err.message;
    });
};

export const updateRecipe = async (data: Partial<IRecipes>) => {
  return await recipesService
    .patch(data?.id!, data)
    .then((res: IRecipes) => {
      // console.log({res});
      return res;
    })
    .catch((err: any) => {
      return err.message;
    });
};

export const deleteRecipe = async (id: number) => {
  return await recipesService
    .remove(id.toString())
    .then((res: any) => {
      return res;
    })
    .catch((err: any) => {
      return err.message;
    });
};

export const RecipesChangeModalState =
  (): RecipesActions.IRecipesModalState => ({
    type: RecipesActions.RECIPES_MODAL_STATE,
  });

export const refreshTableRecipes = (
  val: boolean
): RecipesActions.ISwitchRecipesRefreshTable => ({
  type: RecipesActions.SWITCH_RECIPES_REFRESH_TABLE,
  payload: val,
});
