import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import {
  // RecipesChangeModalState,
  deleteRecipe,
  refreshTableRecipes,
  setRecipesForUpdate,
  setIsRecipesForUpdate,
} from "../../../redux";
import { DataRecipe } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { useRecipes } from "./useRecipes";

const { confirm } = Modal;

export const useTableRecipes = () => {
  const dispatch = useAppDispatch();
  const { tableProps } = useRecipes();

  const changeRecipeForUpdate = (val: any) => {
    dispatch(setIsRecipesForUpdate(true));
    dispatch(setRecipesForUpdate(val));
    // dispatch(RecipesChangeModalState());
  };

  const showDeleteConfirm = (valId: number, val: string) => {
    confirm({
      title: `Estás seguro de borrar la Sede con el id: ${valId}`,
      icon: <ExclamationCircleOutlined />,
      content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a "${val}", si deseas continuar dale click a "Borrar receta" en caso tal solo oprime en "Cancelar".`,
      okText: "Borrar receta",
      okType: "danger",
      cancelText: "Cancelar",
      async onOk() {
        await deleteRecipe(valId)
          .then((res) => {
            dispatch(refreshTableRecipes(true));
            if(res[0].name){
              message.success(`Se ha borrado con éxito la Receta con id:${valId}`);
            }else if(res){
              console.log({res})
              message.error(`${res}`);
            }
            tableProps?.refresh();
          })
          .catch((err) => {
            console.log({ err });
            message.error(err.message);
          });
      },
      onCancel() {},
    });
  };

  return {
    //state
    //methods
    //functions
    changeRecipeForUpdate,
    showDeleteConfirm,
  };
};
