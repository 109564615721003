import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";

import { AdminMenuLayout, ModalRecipes } from "../../../shared/components";
import { WrapperCardRecipes } from "./styled";
import { useRecipes } from "../hooks";
import { selectModalRecipes } from "../../../redux";
import { TableRecipes } from "./TableRecipes";

export const Recipes = () => {
  const modalrecipes = useSelector(selectModalRecipes);
  const {
    isUpdateRecipe,
    formMethodsCreate,
    formMethodsUpdate,
    changeModalRecipesState,
  } = useRecipes();

  return (
    <AdminMenuLayout pageDescription="recipes" title="recipes">
      <WrapperCardRecipes>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalRecipesState}
            >
              Agregar nueva Receta
            </Button>
          </Col>
        </Row>
        <TableRecipes />
        {modalrecipes && isUpdateRecipe && (
          <FormProvider {...formMethodsUpdate}>
            <ModalRecipes />
          </FormProvider>
        )}
        {modalrecipes && !isUpdateRecipe && (
          <FormProvider {...formMethodsCreate}>
            <ModalRecipes />
          </FormProvider>
        )}
      </WrapperCardRecipes>
    </AdminMenuLayout>
  );
};
