import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import {
  selectIsUpdateStore,
  selectStoreForUpdate,
  selectStoresRefreshTable,
  setIsStoreForUpdate,
  StoresChangeModalState,
  updateStore,
  refreshTableStores,
  createStore,
} from "../../../redux";
import { message } from "antd";

import { DataStores } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import { validateCreateStores, validateUpdateStores } from "../../../helpers";

export const useStores = () => {
  const dispatch = useAppDispatch();

  const isUpdateStore = useSelector(selectIsUpdateStore);
  const dataUpdateStore = useSelector(selectStoreForUpdate);
  const isRefreshTableStores = useSelector(selectStoresRefreshTable);

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const changeModalStoresState = () => {
    dispatch(setIsStoreForUpdate(false));
    dispatch(StoresChangeModalState());
  };

  const formMethodsCreate = useForm<DataStores>({
    resolver: yupResolver(validateCreateStores),
  });

  const formMethodsUpdate = useForm<DataStores>({
    resolver: yupResolver(validateUpdateStores),
  });

  useEffect(() => {
    if (isUpdateStore) {
      formMethodsUpdate.reset({ ...dataUpdateStore });
      // formMethodsUpdate.reset({ ...dataUpdateStore });
    }

    return () => {
      formMethodsCreate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateStore, dataUpdateStore]);
  const updateStatus = async (value: "active" | "inactive", id: any) => {
    if (isLoading) return;
    setIsLoading(true);
    await updateStore({
      // close_at: value,
      // phone2: value,
      // email: value,
      // schedule: value,
      // close_at: value,
    })
      .then((res: Partial<DataStores>) => {
        setIsLoading(false);
        if (res.id) {
          // dispatch(bannersChangeModalState());
          message.success(
            `Se ha actualizado con éxito el estado de la marca con id:${res.id}`
          );
          dispatch(refreshTableStores(true));
        } else {
          message.error(res as string);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
        message.error(err.message);
      });
  };

  const onSubmitCreateOrUpdate = async (payload: DataStores) => {
    const { state_id, ...data } = payload;
    console.log({ data, dataUpdateStore });

    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateStore) {
      //console.log("Actualiando===>", data);
      await updateStore({
        id: dataUpdateStore?.id,
        name: data.name,
        // status: data.status,
        phone: data.phone,
        email: data.email,
        schedule: data.schedule,
        address: data.address,
        // open_at: data.open_at,
        // close_at: data.close_at,
        image_path: data.image_path,
      })
        .then((res: Partial<DataStores>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            // dispatch(StoresChangeModalState());
            message.success(
              `Se ha actualizado con éxito la marca con id:${res.id}`
            );
            dispatch(refreshTableStores(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      await createStore({
        ...data,
        location_city_id: Number(data.location_city_id),
      })
        .then((res: DataStores) => {
          setIsLoading(false);
          if (res.id) {
            dispatch(StoresChangeModalState());
            message.success(`Se ha creado con éxito la marca con id:${res.id}`);
            dispatch(refreshTableStores(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableStores) {
      tableProps?.refresh();
      dispatch(refreshTableStores(false));
    }
  }, [isRefreshTableStores]);

  return {
    //state
    isUpdateStore,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    changeModalStoresState,
    updateStatus,
  };
};
