import { useSelector } from "react-redux";
import { useUsers } from "../../../../modules/users/hooks/useUsers";
import {
  selectModalUser,
  selectIsUpdateUser,
  selectUserForUpdate,
} from "../../../../redux";
import { WrapperModalUsers } from "./styled";
import { useFormContext } from "react-hook-form";
import { IUserResFind } from "../../../../redux/@types";
import { Button, Col, Typography } from "antd";
import { InputNumber, InputPassword, InputSelect, InputText } from "../inputs";

const { Title } = Typography;

const dataOptionsRole = [
  { value: "user", title: "Usuario" },
  { value: "seller", title: "Vendedor" },
  { value: "admin", title: "Administrador" },
  { value: "domiciliary", title: "Domiciliario" },
];

const dataOptionsStatus = [
  { value: "active", title: "Activo" },
  { value: "inactive", title: "Inactivo" },
  {
    value: "pending security verification",
    title: "Verificación de seguridad pendiente",
  },
  { value: "pending user data", title: "Datos de usuario pendientes" },
  { value: "pending validation", title: "Validación pendiente" },
];

export const ModalUsers = () => {
  const modalUser = useSelector(selectModalUser);
  const isUpdateUser = useSelector(selectIsUpdateUser);
  const updateUser = useSelector(selectUserForUpdate);
  const { isLoading, changeModalUserState, onSubmitCreateOrUpdate } =
    useUsers();

  const { control, handleSubmit: onSubmit } = useFormContext<IUserResFind>();

  return (
    <WrapperModalUsers
      title={
        isUpdateUser
          ? `Actualizando usuario: '${updateUser?.id!}'`
          : `Creando usuario`
      }
      open={modalUser}
      width={"320px"}
      destroyOnClose
      //   onOk={handleOk}
      onClose={changeModalUserState}
    >
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Nombre:
        </Title>
        <InputText
          placeHolder="Juan David"
          name="first_name"
          control={control}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Apellidos:
        </Title>
        <InputText
          placeHolder="Velázquez Molina"
          name="last_name"
          control={control}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Cédula:
        </Title>
        <InputNumber
          stringMode={true}
          placeHolder="123456789"
          name="dni"
          control={control}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Correo:
        </Title>
        <InputText
          placeHolder="example@example.com"
          name="email"
          control={control}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Contraseña:
        </Title>
        <InputPassword name="password" control={control} />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Teléfono:
        </Title>
        <InputNumber
          stringMode={true}
          placeHolder="3292321143"
          name="phone"
          control={control}
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Rol:
        </Title>
        <InputSelect
          name="role"
          control={control}
          dataOptions={dataOptionsRole}
          label="Role"
        />
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Title style={{ marginBottom: "1.5px" }} level={5}>
          Estado:
        </Title>
        <InputSelect
          name="status"
          control={control}
          dataOptions={dataOptionsStatus}
          label="Estado"
        />
      </Col>
      <Button
        style={{
          width: "100%",
          marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
      >
        {isUpdateUser ? "Actualizar información" : "Crear usuario"}
      </Button>
    </WrapperModalUsers>
  );
};
