import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import {
  selectIsUpdateRecipe,
  selectRecipeForUpdate,
  selectRecipeRefreshTable,
  setIsRecipesForUpdate,
  RecipesChangeModalState,
  updateRecipe,
  refreshTableRecipes,
  createRecipe,
} from "../../../redux";
import { message } from "antd";

import { DataRecipe } from "../../../redux/@types";
import useAppDispatch from "../../../redux/hooks/useAppDispatch";
import { TableProperties } from "../../../shared/components/ui/Table/types";
import { validateCreateRecipes, validateUpdateRecipes } from "../../../helpers";

export const useRecipes = () => {
  const dispatch = useAppDispatch();

  const isUpdateRecipe = useSelector(selectIsUpdateRecipe);
  const dataUpdateRecipe = useSelector(selectRecipeForUpdate);
  const isRefreshTableRecipes = useSelector(selectRecipeRefreshTable);

  const [isLoading, setIsLoading] = useState(false);
  const [tableProps, setTableProps] = useState<TableProperties>();

  const changeModalRecipesState = () => {
    dispatch(setIsRecipesForUpdate(false));
    dispatch(RecipesChangeModalState());
  };

  const formMethodsCreate = useForm<DataRecipe>({
    resolver: yupResolver(validateCreateRecipes),
  });

  const formMethodsUpdate = useForm<DataRecipe>({
    resolver: yupResolver(validateUpdateRecipes),
  });

  useEffect(() => {
    if (isUpdateRecipe) {
      formMethodsUpdate.reset({ ...dataUpdateRecipe });
      // formMethodsUpdate.reset({ ...dataUpdateRecipe });
    }

    return () => {
      formMethodsCreate.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateRecipe, dataUpdateRecipe]);
  const updateStatus = async (value: "active" | "inactive", id: any) => {
    if (isLoading) return;
    setIsLoading(true);
    await updateRecipe({
      // close_at: value,
      // phone2: value,
      // email: value,
      // schedule: value,
      // close_at: value,
    })
      .then((res: Partial<DataRecipe>) => {
        setIsLoading(false);
        if (res.id) {
          // dispatch(bannersChangeModalState());
          message.success(
            `Se ha actualizado con éxito el estado de la marca con id:${res.id}`
          );
          dispatch(refreshTableRecipes(true));
        } else {
          message.error(res as string);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        console.log(err);
        message.error(err.message);
      });
  };

  const onSubmitCreateOrUpdate = async (payload: DataRecipe) => {
    // const { state_id, ...data } = payload;
    // console.log({ data, dataUpdateRecipe });

    if (isLoading) return;
    setIsLoading(true);
    if (isUpdateRecipe) {
      //console.log("Actualiando===>", data);
      await updateRecipe({
        ...payload,
      })
        .then((res: Partial<DataRecipe>) => {
          setIsLoading(false);
          // dispatch(mappingUpdatedUser(res))
          if (res.id) {
            // dispatch(RecipesChangeModalState());
            message.success(
              `Se ha actualizado con éxito la receta con id:${res.id}`
            );
            dispatch(refreshTableRecipes(true));
          } else {
            message.error(res as string);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    } else {
      // console.log('Creando===>',data)
      await createRecipe({
        ...payload,
        // location_city_id: Number(data.location_city_id),
      })
        .then((res: DataRecipe) => {
          setIsLoading(false);
          if (res.id) {
            // dispatch(RecipesChangeModalState());
            message.success(
              `Se ha creado con éxito la receta con id:${res.id}`
            );
            dispatch(refreshTableRecipes(true));
          } else {
            message.error(res as any);
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          console.log(err);
          message.error(err.message);
        });
    }
  };

  useEffect(() => {
    if (isRefreshTableRecipes) {
      tableProps?.refresh();
      dispatch(refreshTableRecipes(false));
    }
  }, [isRefreshTableRecipes]);

  return {
    //state
    isUpdateRecipe,
    isLoading,
    tableProps,
    //methods
    formMethodsCreate,
    formMethodsUpdate,
    setTableProps,
    //functions
    onSubmitCreateOrUpdate,
    changeModalRecipesState,
    updateStatus,
  };
};
