// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Avatar, Button, Card, Col, message, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { UndoOutlined } from "@ant-design/icons";

import {
  selectOrderShippedModalIsOpen,
  selectOrderShipping,
} from "../../../../redux/selectors/orders.selectors";
import { useOrderById } from "../../../../modules/orders/hooks/orderById";
import { WrapperModalOrderShipped } from "./styled";
import { PATH_S3_BUCKET } from "../../../utils/constants";
import { shippingQuantityService, shippingService } from "../../../services";
import { DataShipping } from "../../../../redux/@types";

export interface IOrderDetail {
  id: number;
  order_id: number;
  product_id: number;
  quantity: number;
  unit_price_tax_excl: number;
  unit_price_tax_incl: number;
  sent: number;
  total_price_tax: number;
  total_price_tax_incl: number;
  unit_price_tax: number;
  product_name: string;
  product_main_image: string;
  product_details_meta_data: string;
  scheduled_delivery_date: null;
  deletedAt: null;
  createdAt: Date;
  updatedAt: Date;
  discount_id: null;
  meta_discount_name: null;
  meta_discount_code: null;
  meta_discount_value_percentage: null;
  meta_discount_value_amount: null;
  discount_total_amount: null;
  shippings_details: IDataOrderDetail[];
  product_integration_id: null;
}

export interface IDataOrderDetail {
  id: number;
  shipping_id: number;
  order_id: number;
  order_detail_id: number;
  quantity: number;
  deletedAt: null;
  createdAt: Date;
  updatedAt: Date;
  order_detail?: IOrderDetail;
}

interface Props {
  orderShippingShow: DataShipping;
}

export const ModalOrdersShipped = ({ orderShippingShow }: Props) => {
  const modalOrdersShipped = useSelector(selectOrderShippedModalIsOpen);
  const [loading, setLoading] = useState(false);
  const [dataShipped, setDataShipped] = useState<IDataOrderDetail[] | []>([]);
  const { changeOrderShippedModal } = useOrderById();
  // console.log({ orderShippingShow });
  const getAllShippingDetails = async () => {
    return await shippingQuantityService
      .find({ query: { shipping_id: orderShippingShow.id } })
      .then((res) => {
        // setDataShipped([...dataShipped, res.data]);
        setDataShipped(res.data);
      })
      .catch((err: any) => {
        console.log(err);
        message.error(
          `No se pudo cargar correctamente la orden ${orderShippingShow.id} revisar la consola.`
        );
      });
  };

  useEffect(() => {
    getAllShippingDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleMarkSent = () => {
    // const service = getService("shipping");
    if (orderShippingShow.id) {
      setLoading(true);
      shippingService
        .patch(orderShippingShow.id, {
          shipping_status_id: 3
        })
        .then((resp: any) => {
          console.log({resp});
          setLoading(false);
          message.success("Envío Entregado!");
        })
        .catch((err: any) => {
          setLoading(false);
          message.error(err.message);
        });
    }
  };

  // console.log({ dataShipped });
  const columns: ColumnsType<IDataOrderDetail> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      filterType: "text",
    },
    {
      title: "Foto",
      dataIndex: "product_main_image",
      key: "product_main_image",
      render: (_, item) => (
        <Col
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-start",
            flexDirection: "row",
          }}
        >
          <Avatar
            size={64}
            src={`${PATH_S3_BUCKET}/${item?.order_detail?.product_main_image}`}
          />
        </Col>
      ),
    },
    {
      title: "Nombre",
      dataIndex: ["order_detail", "product_name"],
      key: "name",
    },
    {
      title: "Cantidad comprada",
      dataIndex: ["order_detail", "quantity"],
      key: "quantity",
    },
    {
      title: "Cantidad enviada",
      dataIndex: ["order_detail", "sent"],
      key: "sent",
    },
    {
      title: "Cantidad Pendiente por Envío",
      dataIndex: "quantityPendingForSent",
      key: "quantityPendingForSent",
      render: (_, item) => (
        <>{item?.order_detail?.quantity - item?.order_detail?.sent}</>
      ),
    },
  ];
  // console.log({ dataShipped });
  return (
    <WrapperModalOrderShipped
      title={`Detalle del envío`}
      open={modalOrdersShipped}
      width={"93%"}
      style={{
        maxWidth: "1400px",
      }}
      destroyOnClose
      onCancel={changeOrderShippedModal}
    >
      <Col
        span={24}
        style={{
          width: "100%",
          display: "flex",
          margin: "auto",
          // padding: 2.5,
          justifyContent: "flex-start",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        {orderShippingShow?.private_notes && (
          <Col span={24} style={{ width: "100%" }}>
            <Card title={<h3>Notas Privadas:</h3>} hoverable bordered>
              <div>
                <article>{orderShippingShow?.private_notes!}</article>
              </div>
            </Card>
          </Col>
        )}
        <Button
          style={{ marginBottom: "5px" }}
          type="primary"
          shape="circle"
          icon={<UndoOutlined />}
          onClick={getAllShippingDetails}
        />
        <Table
          style={{ width: "100%", marginTop: "16px" }}
          pagination={false}
          bordered
          columns={columns}
          dataSource={dataShipped}
        />
        {orderShippingShow.shipping_status_id == 2 && (
        <Row type="flex" justify="center" align="middle">
          <Col>
            <Button
              loading={loading}
              block
              type="primary"
              size="large"
              onClick={handleMarkSent}
            >
              Marcar como Entregado
            </Button>
          </Col>
        </Row>
      )}
      </Col>
    </WrapperModalOrderShipped>
  );
};
