import { TRootState } from "../@types";

export const selectModalStores = (state: TRootState) =>
  state.stores.StoresModalIsOpen;

export const selectIsUpdateStore = (state: TRootState) =>
  state.stores.isUpdateStore;

export const selectStoreForUpdate = (state: TRootState) =>
  state.stores.StoresForUpdate;

export const selectStoresRefreshTable = (state: TRootState) =>
  state.stores.refreshTableStores;
