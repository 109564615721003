import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Typography } from "antd";

import { useRecipes, useTableRecipes } from "../hooks";
import Table from "../../../shared/components/ui/Table";
import { recipesService } from "../../../shared/services";
import { DataRecipe } from "../../../redux/@types";
import { TableColumns } from "../../../shared/components/ui/Table/types";
import { PATH_S3_BUCKET } from "../../../shared/utils/constants";

const { Title } = Typography;

export const TableRecipes = () => {
  const { changeRecipeForUpdate, showDeleteConfirm } = useTableRecipes();
  const { setTableProps, updateStatus, isLoading } = useRecipes();

  const columns: TableColumns<DataRecipe> = [
    {
      title: "ID",
      width: 3,
      dataIndex: "id",
      key: "id",
      filterType: "text",
    },
    {
      title: "Image",
      width: 7,
      dataIndex: "path_cover",
      key: "path_cover",
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Avatar size={64} src={`${PATH_S3_BUCKET}/${item.path_cover}`} />
          </Col>
        );
      },
    },
    {
      title: "Calificacion",
      width: 9,
      dataIndex: "rating_score",
      key: "rating_score",
      filterType: "text",
    },
    {
      title: "Total de likes",
      width: 6,
      dataIndex: "likes_unlikes_total",
      key: "likes_unlikes_total",
    },
    
    {
      title: "Acciones",
      key: "operation",
      fixed: "right",
      width: 4,
      render: (_, item) => {
        return (
          <Col
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              flexDirection: "row",
            }}
          >
            <Button
              type="primary"
              shape="circle"
              onClick={() => changeRecipeForUpdate(item)}
              icon={<EditOutlined />}
              size="large"
            />
            <Button
              type="primary"
              danger
              onClick={() => showDeleteConfirm(item.id, `${item.name}`)}
              shape="circle"
              icon={<DeleteOutlined />}
              size="large"
            />
          </Col>
        );
      },
    },
  ];

  return (
    <Table<DataRecipe>
      columns={columns}
      fetchQueryProps={{
        $sort: { id: -1 },
      }}
      service={recipesService}
      onLoad={setTableProps}
    />
  );
};
