import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import useAppDispatch from "../../../../redux/hooks/useAppDispatch";
import { useProductPremiumCharacteristics } from "./useProductPremiumCharacteristics";
import { setIsProductsPremiumCharacteristicForUpdate, setProductsPremiumCharacteristicForUpdate } from "../../../../redux";
import { ProductsPremiumCharacteristicChangeModalState, deleteProductPremiumCharacteristic, refreshTableProductsPremiumCharacteristic } from '../../../../redux/actions/products.actions';

const { confirm } = Modal;

export const useTableProductPremiumCharacteristics = () => {
    const dispatch = useAppDispatch();
    const { tableProps } = useProductPremiumCharacteristics();

    const changeProductPremiumCharacteristicForUpdate = (user: any) => {
        dispatch(setIsProductsPremiumCharacteristicForUpdate(true));
        dispatch(setProductsPremiumCharacteristicForUpdate(user));
        dispatch(ProductsPremiumCharacteristicChangeModalState());
    };

    const showDeleteConfirm = (valId: number, val: string) => {
        confirm({
          title: `Estás seguro de borrar la característica premium asociada al producto con el id: ${valId}`,
          icon: <ExclamationCircleOutlined />,
          content: `Una vez ejecutada esta acción no hay vuelta atrás, estás por borrar a la característica asociada al producto de id: "${val}", si deseas continuar dale click a "Borrar característica" en caso tal no queras borrar nada solo oprime en "Cancelar".`,
          okText: "Borrar característica",
          okType: "danger",
          cancelText: "Cancelar",
          keyboard: true,
          maskClosable: true,
          async onOk() {
            await deleteProductPremiumCharacteristic(valId)
              .then(() => {
                dispatch(refreshTableProductsPremiumCharacteristic(true));
                message.success(`Se ha borrado la característica premium asociada al producto con id:${valId}`);
                tableProps?.refresh();
              })
              .catch((err) => {
                console.log({ err });
                message.error(err.message);
              });
          },
          onCancel() {},
        });
    };

    return {
        //state
        //methods
        //functions
        changeProductPremiumCharacteristicForUpdate,
        showDeleteConfirm,
      };
  }
  