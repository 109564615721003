import { Button, Col, Typography } from "antd";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { useProductPremiumCharacteristics, WrapperModalProducts } from "../../../../modules/products";
import { selectIsUpdateProductPremiumCharacteristic, selectModalProductPremiumCharacteristic, selectProductPremiumCharacteristicsForUpdate } from "../../../../redux";
import { IProductPremiumCharacteristics } from "../../../../redux/@types";
import { InputHTML } from "../inputs";

const { Title } = Typography;

export const ModalProductPremiumCharacteristics = () => {
    const modalProductPremiumCharacteristics = useSelector(selectModalProductPremiumCharacteristic);
    const isUpdateProductPremiumCharacteristics = useSelector(selectIsUpdateProductPremiumCharacteristic);
    const updateProductPremiumCharacteristics = useSelector(selectProductPremiumCharacteristicsForUpdate);

    const {
        isLoading,
        onSubmitCreateOrUpdate,
        navigateProductPremiumCharacteristicCreate,
    } = useProductPremiumCharacteristics();

    const { control, handleSubmit: onSubmit } = useFormContext<IProductPremiumCharacteristics>();

  return (
    <WrapperModalProducts
        title={
            isUpdateProductPremiumCharacteristics
            ? `Actualizando receta de producto: '${updateProductPremiumCharacteristics?.id!}'`
            : `Creando receta de producto`
        }
        open={modalProductPremiumCharacteristics}
        width={"620px"}
        destroyOnClose
        onClose={navigateProductPremiumCharacteristicCreate}
    >
        <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Descripción:
            </Title>
            {isUpdateProductPremiumCharacteristics ? (
              <InputHTML
                placeHolder="Es un producto muy optimo para..."
                name="html_description"
                control={control}
                isUpdating
              />
            ) : (
              <InputHTML
                placeHolder="Es un producto muy optimo para..."
                name="html_description"
                control={control}
              />
            )}
        </Col>
        <Button
        style={{
            width: "auto",
            marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
        >
        {isUpdateProductPremiumCharacteristics
            ? "Actualizar información"
            : "Crear característica de producto"}
        </Button>
    </WrapperModalProducts>
  )
}
