import { Button, Col, Row, Typography } from "antd";
import { FormProvider } from "react-hook-form";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { TableColumns } from "../../../../shared/components/ui/Table/types";
import Table from "../../../../shared/components/ui/Table";
import { selectModalProductRecipes } from "../../../../redux";
import { useTableProductsRecipes } from "../../hooks/productById/useTableProductsRecipes";
import { useProductRecipes } from "../../hooks/productById/useProductRecipes";
import { IProductRecipes } from '../../../../redux/@types/products';
import { productsRecipesService } from "../../../../shared/services";
import { ModalProductRecipes } from "../../../../shared/components";

const { Title } = Typography;

export const TableProductsRecipes = () => {
    const params = useParams();

    const modalProductRecipe = useSelector(
        selectModalProductRecipes
    );
    const { changeProductRecipeForUpdate, showDeleteConfirm } = useTableProductsRecipes();
    const {
        isUpdateProductRecipe,
        setTableProps,
        navigateProductRecipeCreate,
        formMethodsCreate,
        formMethodsUpdate,
    } = useProductRecipes();

    const columns: TableColumns<IProductRecipes> = [
        {
          title: "ID",
          width: 5,
          dataIndex: "id",
          key: "id",
          filterType: "filterByID",
        },
        {
          title: "Receta",
          width: 10,
        //   dataIndex: ["recipe","name"],
        dataIndex: 'data_value',
        //   key: "recipe_id",
            render: (_, item) => {
                return (
                  <Col
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                    }}
                  >
                    <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
                      {item.recipe.name}
                    </Title>
                  </Col>
                );
              },
            // filterType: "text",
        },
        {
          title: "Acciones",
          key: "operation",
          fixed: "right",
          width: 1,
          render: (_, item) => {
            return (
              <Col
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                }}
              >
                <Button
                  type="primary"
                  shape="circle"
                  onClick={() => changeProductRecipeForUpdate(item)}
                  icon={<EditOutlined />}
                  size="large"
                />
                <Button
                  type="primary"
                  danger
                  onClick={() =>
                    showDeleteConfirm(item.id, `${item.recipe_id}`)
                  }
                  shape="circle"
                  icon={<DeleteOutlined />}
                  size="large"
                />
              </Col>
            );
          },
        },
    ];

  return (
    <Row justify="start">
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <Button
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
          size="large"
          onClick={navigateProductRecipeCreate}
        >
          Agregar nueva receta
        </Button>
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Table<IProductRecipes>
          columns={columns}
          scroll={{ x: 'max-content' }}
          fetchQueryProps={{
            product_id: params.id,
            $sort: { id: -1 },
          }}
          service={productsRecipesService}
          onLoad={setTableProps}
        />
    </Col>
    {modalProductRecipe && isUpdateProductRecipe && (
        <FormProvider {...formMethodsUpdate}>
            <ModalProductRecipes />
        </FormProvider>
    )}
    {modalProductRecipe && !isUpdateProductRecipe && (
        <FormProvider {...formMethodsCreate}>
            <ModalProductRecipes />
        </FormProvider>
    )}
    </Row>
  )
}
