// import { Datarecipes, IrecipesState } from "../@types";
import { RecipesActions } from "../action-types";

type recipesActionType =
  | { type: typeof RecipesActions.GET_RECIPE_FOR_UPDATE; payload: any }
  | { type: typeof RecipesActions.IS_RECIPE_FOR_UPDATE; payload: boolean }
  | {
      type: typeof RecipesActions.SWITCH_RECIPES_REFRESH_TABLE;
      payload: boolean;
    };

const INITIAL_STATE: any = {
  recipesForUpdate: undefined,
  isUpdaterecipe: false,
  recipesModalIsOpen: false,
  refreshTablerecipes: false,
};

export default function recipesReducer(
  state: any = INITIAL_STATE,
  action: recipesActionType
): any {
  switch (action.type) {
    case RecipesActions.GET_RECIPE_FOR_UPDATE:
      return {
        ...state,
        recipesForUpdate: action.payload,
      };
    case RecipesActions.IS_RECIPE_FOR_UPDATE:
      return {
        ...state,
        isUpdaterecipe: action.payload,
      };
    case RecipesActions.SWITCH_RECIPES_REFRESH_TABLE:
      return {
        ...state,
        refreshTablerecipes: action.payload,
      };
    default:
      return state;
  }
}
