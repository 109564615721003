import { Button, Col, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { FormProvider } from "react-hook-form";

import { AdminMenuLayout, ModalStores } from "../../../shared/components";
import { WrapperCardStores } from "./styled";
import { useStores } from "../hooks";
import { selectModalStores } from "../../../redux";
import { TableStores } from "./TableStores";

export const Stores = () => {
  const modalStores = useSelector(selectModalStores);
  const {
    isUpdateStore,
    formMethodsCreate,
    formMethodsUpdate,
    changeModalStoresState,
  } = useStores();

  return (
    <AdminMenuLayout pageDescription="Stores" title="Stores">
      <WrapperCardStores>
        <Row
          gutter={[8, 8]}
          style={{
            padding: "10px",
            width: "100%",
          }}
        >
          <Col
            span={24}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              shape="round"
              icon={<PlusOutlined />}
              size="large"
              onClick={changeModalStoresState}
            >
              Agregar nueva sede
            </Button>
          </Col>
        </Row>
        <TableStores />
        {modalStores && isUpdateStore && (
          <FormProvider {...formMethodsUpdate}>
            <ModalStores />
          </FormProvider>
        )}
        {modalStores && !isUpdateStore && (
          <FormProvider {...formMethodsCreate}>
            <ModalStores />
          </FormProvider>
        )}
      </WrapperCardStores>
    </AdminMenuLayout>
  );
};
