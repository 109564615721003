import * as yup from "yup";

export const validateUpdateStores = yup.object().shape({
  name: yup.string().min(2, "El NOMBRE debe tener más de 2 caracteres"),
  status: yup
    .string()
    .oneOf(
      [
        "active",
        "inactive",
        "pending security verification",
        "pending user data",
        "pending validation",
      ],
      "Debes elegir entre estas opciones"
    ),
  description: yup
    .string()
    .min(2, "LA DESCRIPCIÓN debe tener más de 2 caracteres"),
  priority: yup
    .number()
    .typeError("Campo requerido.")
    .positive()
    .min(0, "Debe de utilizar un numero que no sea negativo"),
  //path_logo:yup
  // .string()
  // .min(2, 'La IMAGEN debe tener más de 2 caracteres'),
});
