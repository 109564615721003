import * as yup from 'yup'

export const validateCreateProductRecipes = yup.object().shape({
    recipe_id:yup
    .string()
    .required('Campo requerido.')
    .min(1, 'LA RECETA debe tener más de 1 caracteres'),
})


