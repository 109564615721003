import { IProductsState } from "../@types";
import { productsActions } from "../action-types";
import { IGenericArrProduct, IProductCharacteristics2, IProductPremiumCharacteristics, IProductPremiumPreparationSteps, IProductRecipes, IProducts } from '../@types/products';

type productsActionType =
| { type: typeof productsActions.GET_PRODUCT_FOR_UPDATE; payload: IProducts }
| { type: typeof productsActions.IS_PRODUCT_FOR_UPDATE; payload: boolean }
| { type: typeof productsActions.SWITCH_PRODUCTS_REFRESH_TABLE; payload: boolean }
| { type: typeof productsActions.GET_CATEGORIES_PRODUCTS; payload: IGenericArrProduct[] }
| { type: typeof productsActions.GET_BRANDS_PRODUCTS; payload: IGenericArrProduct[] }
| { type: typeof productsActions.GET_TAX_PRODUCTS; payload: IGenericArrProduct[] }
//productCharacteristic
| { type: typeof productsActions.PRODUCTS_CHARACTERISTICS_CHANGE_MODAL_STATE }
| { type: typeof productsActions.GET_PRODUCT_CHARACTERISTICS_FOR_UPDATE; payload: IProductCharacteristics2 }
| { type: typeof productsActions.IS_PRODUCT_CHARACTERISTICS_FOR_UPDATE; payload: boolean }
| { type: typeof productsActions.SWITCH_PRODUCTS_CHARACTERISTICS_REFRESH_TABLE; payload: boolean }
| { type: typeof productsActions.GET_CHARACTERISTIC_PRODUCTS; payload: IGenericArrProduct[] }
//productRecipe
| { type: typeof productsActions.PRODUCTS_RECIPES_CHANGE_MODAL_STATE }
| { type: typeof productsActions.GET_PRODUCT_RECIPES_FOR_UPDATE; payload: IProductRecipes }
| { type: typeof productsActions.IS_PRODUCT_RECIPES_FOR_UPDATE; payload: boolean }
| { type: typeof productsActions.SWITCH_PRODUCTS_RECIPES_REFRESH_TABLE; payload: boolean }
| { type: typeof productsActions.GET_RECIPES_FOR_PRODUCTS; payload: IGenericArrProduct[] }
//product-premium-characteristics
| { type: typeof productsActions.PRODUCTS_PREMIUM_CHARACTERISTICS_CHANGE_MODAL_STATE }
| { type: typeof productsActions.GET_PRODUCT_PREMIUM_CHARACTERISTICS_FOR_UPDATE; payload: IProductPremiumCharacteristics }
| { type: typeof productsActions.IS_PRODUCT_PREMIUM_CHARACTERISTICS_FOR_UPDATE; payload: boolean }
| { type: typeof productsActions.SWITCH_PRODUCTS_PREMIUM_CHARACTERISTICS_REFRESH_TABLE; payload: boolean }
//product_premium_preparation_steps
| { type: typeof productsActions.PRODUCT_PREMIUM_PREPARATION_STEPS_CHANGE_MODAL_STATE }
| { type: typeof productsActions.GET_PRODUCT_PREMIUM_PREPARATION_STEPS_FOR_UPDATE; payload: IProductPremiumPreparationSteps }
| { type: typeof productsActions.IS_PRODUCT_PREMIUM_PREPARATION_STEPS_FOR_UPDATE; payload: boolean }
| { type: typeof productsActions.SWITCH_PRODUCT_PREMIUM_PREPARATION_STEPS_REFRESH_TABLE; payload: boolean }

const INITIAL_STATE: IProductsState = {
    productsForUpdate: undefined,
    isUpdateProducts: false,
    refreshTableProducts: false,

    productCharacteristicsForUpdate: undefined,
    isUpdateProductCharacteristics:false,
    refreshTableProductCharacteristics:false,
    productsCharacteristicModalIsOpen: false,

    productsRecipesForUpdate: undefined,
    isUpdateProductsRecipes:false,
    refreshTableProductsRecipes:false,
    productsRecipeModalIsOpen: false,

    ProductPremiumCharacteristicsForUpdate: undefined,
    isUpdateProductPremiumCharacteristics: false,
    refreshTableProductPremiumCharacteristics: false,
    ProductPremiumCharacteristicModalIsOpen: false,

    productPremiumPreparationStepsForUpdate: undefined,
    isUpdateProductPremiumPreparationSteps: false,
    refreshTableProductPremiumPreparationSteps: false,
    productPremiumPreparationStepsModalIsOpen: false,

    arrCategories: [],
    arrBrands: [],
    arrTax: [],
    arrCharacteristic: [],
    arrRecipes: [],
}

export default function productsReducer(state: IProductsState = INITIAL_STATE, action: productsActionType ): IProductsState {
    switch ( action.type ) {
        case productsActions.GET_PRODUCT_FOR_UPDATE:
            return{
                ...state,
                productsForUpdate: action.payload
            }
        case productsActions.IS_PRODUCT_FOR_UPDATE:
            return{
                ...state,
                isUpdateProducts: action.payload
            }
        case productsActions.SWITCH_PRODUCTS_REFRESH_TABLE:
            return{
                ...state,
                refreshTableProducts: action.payload
            }
        case productsActions.GET_CATEGORIES_PRODUCTS:
            return{
                ...state,
                arrCategories: action.payload
            }
        case productsActions.GET_BRANDS_PRODUCTS:
            return{
                ...state,
                arrBrands: action.payload
            }
        case productsActions.GET_TAX_PRODUCTS:
            return{
                ...state,
                arrTax: action.payload
            }
        //productCharacteristics
        case productsActions.PRODUCTS_CHARACTERISTICS_CHANGE_MODAL_STATE:
            return{
                ...state,
                productsCharacteristicModalIsOpen: !state.productsCharacteristicModalIsOpen
            }
        case productsActions.GET_PRODUCT_CHARACTERISTICS_FOR_UPDATE:
            return{
                ...state,
                productCharacteristicsForUpdate: action.payload
            }
        case productsActions.IS_PRODUCT_CHARACTERISTICS_FOR_UPDATE:
            return{
                ...state,
                isUpdateProductCharacteristics: action.payload
            }
        case productsActions.SWITCH_PRODUCTS_CHARACTERISTICS_REFRESH_TABLE:
            return{
                ...state,
                refreshTableProducts: action.payload
            }
        case productsActions.GET_CHARACTERISTIC_PRODUCTS:
            return{
                ...state,
                arrCharacteristic: action.payload
            }
            //productRecipes
        case productsActions.PRODUCTS_RECIPES_CHANGE_MODAL_STATE:
            return{
                ...state,
                productsRecipeModalIsOpen: !state.productsRecipeModalIsOpen
            }
        case productsActions.GET_PRODUCT_RECIPES_FOR_UPDATE:
            return{
                ...state,
                productsRecipesForUpdate: action.payload
            }
        case productsActions.IS_PRODUCT_RECIPES_FOR_UPDATE:
            return{
                ...state,
                isUpdateProductsRecipes: action.payload
            }
        case productsActions.SWITCH_PRODUCTS_RECIPES_REFRESH_TABLE:
            return{
                ...state,
                refreshTableProductsRecipes: action.payload
            }
        case productsActions.GET_RECIPES_FOR_PRODUCTS:
            return{
                ...state,
                arrRecipes: action.payload
            }
             //product-premium-characteristics
        case productsActions.PRODUCTS_PREMIUM_CHARACTERISTICS_CHANGE_MODAL_STATE:
            return{
                ...state,
                ProductPremiumCharacteristicModalIsOpen: !state.ProductPremiumCharacteristicModalIsOpen
            }
        case productsActions.GET_PRODUCT_PREMIUM_CHARACTERISTICS_FOR_UPDATE:
            return{
                ...state,
                ProductPremiumCharacteristicsForUpdate: action.payload
            }
        case productsActions.IS_PRODUCT_PREMIUM_CHARACTERISTICS_FOR_UPDATE:
            return{
                ...state,
                isUpdateProductPremiumCharacteristics: action.payload
            }
        case productsActions.SWITCH_PRODUCTS_PREMIUM_CHARACTERISTICS_REFRESH_TABLE:
            return{
                ...state,
                refreshTableProductPremiumCharacteristics: action.payload
            }
             //product_premium_preparation_steps
        case productsActions.PRODUCT_PREMIUM_PREPARATION_STEPS_CHANGE_MODAL_STATE:
            return{
                ...state,
                productPremiumPreparationStepsModalIsOpen: !state.productPremiumPreparationStepsModalIsOpen
            }
        case productsActions.GET_PRODUCT_PREMIUM_PREPARATION_STEPS_FOR_UPDATE:
            return{
                ...state,
                productPremiumPreparationStepsForUpdate: action.payload
            }
        case productsActions.IS_PRODUCT_PREMIUM_PREPARATION_STEPS_FOR_UPDATE:
            return{
                ...state,
                isUpdateProductPremiumPreparationSteps: action.payload
            }
        case productsActions.SWITCH_PRODUCT_PREMIUM_PREPARATION_STEPS_REFRESH_TABLE:
            return{
                ...state,
                refreshTableProductPremiumPreparationSteps: action.payload
            }
        default:
            return state
    }
}