import { Button, Col, Typography } from "antd";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";

import { WrapperModalProducts } from "../../../../modules/products";
import { selectIsUpdateProductPremiumPreparationSteps, selectModalProductPremiumPreparationSteps, selectProductPremiumPreparationStepsForUpdate } from "../../../../redux";
import { IProductPremiumPreparationSteps } from "../../../../redux/@types";
import { InputHTML, InputText } from "../inputs";
import { useProductPremiumPreparationSteps } from '../../../../modules/products/hooks/productById/useProductPremiumPreparationSteps';

const { Title } = Typography;

export const ModalProductPremiumPreparationsSteps = () => {
    const modalProductPremiumPreparationsSteps = useSelector(selectModalProductPremiumPreparationSteps);
    const isUpdateProductPremiumPreparationsSteps = useSelector(selectIsUpdateProductPremiumPreparationSteps);
    const updateProductPremiumPreparationsSteps = useSelector(selectProductPremiumPreparationStepsForUpdate);

    const {
        isLoading,
        onSubmitCreateOrUpdate,
        navigateProductPremiumPreparationStepsCreate,
    } = useProductPremiumPreparationSteps();

    const { control, handleSubmit: onSubmit } = useFormContext<IProductPremiumPreparationSteps>();

  return (
    <WrapperModalProducts
        title={
            isUpdateProductPremiumPreparationsSteps
            ? `Actualizando pasos de preparación: '${updateProductPremiumPreparationsSteps?.id!}'`
            : `Creando pasos de preparación`
        }
        open={modalProductPremiumPreparationsSteps}
        width={"620px"}
        destroyOnClose
        onClose={navigateProductPremiumPreparationStepsCreate}
    >
        <Col
            span={24}
            style={{
            display: "flex",
            marginTop: "10px",
            padding: 5,
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
            Pasos:
            </Title>
            <InputText
            placeHolder="Lo primero es limpiar la carne..."
            name="step_number"
            control={control}
            rows
            />
        </Col>
        <Col
            span={24}
            style={{
              display: "flex",
              marginTop: "10px",
              padding: 5,
              justifyContent: "center",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Title style={{ marginBottom: "1.5px" }} level={5}>
              Descripción:
            </Title>
            {isUpdateProductPremiumPreparationsSteps ? (
              <InputHTML
                placeHolder="Es un producto muy optimo para..."
                name="html_description"
                control={control}
                isUpdating
              />
            ) : (
              <InputHTML
                placeHolder="Es un producto muy optimo para..."
                name="html_description"
                control={control}
              />
            )}
        </Col>
        <Button
        style={{
            width: "auto",
            marginTop: 10,
        }}
        type="primary"
        shape="round"
        size="large"
        onClick={onSubmit(onSubmitCreateOrUpdate)}
        loading={isLoading}
        >
        {isUpdateProductPremiumPreparationsSteps
            ? "Actualizar información"
            : "Crear pasos de preparación"}
        </Button>
    </WrapperModalProducts>
  )
}
