import { Button, Col, Row, Typography } from "antd";
import { FormProvider } from "react-hook-form";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { TableColumns } from "../../../../shared/components/ui/Table/types";
import Table from "../../../../shared/components/ui/Table";
import { selectModalProductPremiumPreparationSteps } from "../../../../redux";
import { useProductPremiumPreparationSteps, useTableProductPremiumPreparationSteps } from "../../hooks";
import { IProductPremiumPreparationSteps } from "../../../../redux/@types";
import { productsPremiumPreparationsStepsService } from "../../../../shared/services";
import { ModalProductPremiumPreparationsSteps } from "../../../../shared/components";

const { Title } = Typography;

export const TableProductPremiumPreparationSteps = () => {
    const params = useParams();

    const modalProductPremiumPreparationSteps = useSelector(
        selectModalProductPremiumPreparationSteps
    );
    console.log({modalProductPremiumPreparationSteps})
    const { changeProductPremiumPreparationStepsForUpdate, showDeleteConfirm } = useTableProductPremiumPreparationSteps();
    const {
        isUpdateProductPremiumPreparationSteps,
        setTableProps,
        navigateProductPremiumPreparationStepsCreate,
        formMethodsCreate,
        formMethodsUpdate,
    } = useProductPremiumPreparationSteps();

    const columns: TableColumns<IProductPremiumPreparationSteps> = [
        {
          title: "ID",
          width: 5,
          dataIndex: "id",
          key: "id",
          filterType: "filterByID",
        },
        {
          title: "Descripción",
          width: 10,
          dataIndex: 'html_description',
        //   key: "recipe_id",
            render: (_, item) => {
                return (
                  <Col
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                    }}
                  >
                    <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
                      {item.html_description.length > 120 ? item.html_description.substring(0, 120) + "..." : item.html_description}
                    </Title>
                  </Col>
                );
              },
            // filterType: "text",
        },
        {
            title: "Pasos",
            width: 10,
            dataIndex: 'step_number',
          //   key: "recipe_id",
              render: (_, item) => {
                  return (
                    <Col
                      style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                      }}
                    >
                      <Title style={{ fontSize: "0.74rem", fontWeight: "500" }} level={5}>
                        {item.step_number.length > 120 ? item.step_number.substring(0, 120) + "..." : item.step_number}
                      </Title>
                    </Col>
                  );
                },
              // filterType: "text",
          },
        {
          title: "Acciones",
          key: "operation",
          fixed: "right",
          width: 5,
          render: (_, item) => {
            return (
              <Col
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                }}
              >
                <Button
                  type="primary"
                  shape="circle"
                  onClick={() => changeProductPremiumPreparationStepsForUpdate(item)}
                  icon={<EditOutlined />}
                  size="large"
                />
                <Button
                  type="primary"
                  danger
                  onClick={() =>
                    showDeleteConfirm(item.id, `${item.product_id}`)
                  }
                  shape="circle"
                  icon={<DeleteOutlined />}
                  size="large"
                />
              </Col>
            );
          },
        },
    ];
  return (
    <Row justify="start">
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-end",
          flexDirection: "column",
        }}
      >
        <Button
          type="primary"
          shape="round"
          icon={<PlusOutlined />}
          size="large"
          onClick={navigateProductPremiumPreparationStepsCreate}
        >
          Agregar nuevo pasos de preparación
        </Button>
      </Col>
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Table<IProductPremiumPreparationSteps>
          columns={columns}
        //   scroll={{ x: 'max-content' }}
          fetchQueryProps={{
            product_id: params.id,
            $sort: { id: -1 },
          }}
          service={productsPremiumPreparationsStepsService}
          onLoad={setTableProps}
        />
    </Col>
    {modalProductPremiumPreparationSteps && isUpdateProductPremiumPreparationSteps && (
        <FormProvider {...formMethodsUpdate}>
            <ModalProductPremiumPreparationsSteps />
        </FormProvider>
    )}
    {modalProductPremiumPreparationSteps && !isUpdateProductPremiumPreparationSteps && (
        <FormProvider {...formMethodsCreate}>
            <ModalProductPremiumPreparationsSteps />
        </FormProvider>
    )}
    </Row>
  )
}
