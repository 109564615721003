// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { Button, Col, Divider, message, Row, Select, Typography } from "antd";
import { useSelector } from "react-redux";

import { selectProductForUpdate } from "../../../../redux";
import {
  ModalGalleryMedia,
  SimpleInputTextArea,
} from "../../../../shared/components";
import UploadDragAndDrop from "../../../../shared/components/ui/inputs/UploadDragAndDrop";
import { HOST_API, PATH_S3_BUCKET } from "../../../../shared/utils/constants";
import { useProductsGallery } from "../../hooks";
import { MyList } from "../styled";
import { productMediaService } from "../../../../shared/services";

const { Title } = Typography;
const { Option } = Select;

export const ProductsGallery = () => {
  const record = useSelector(selectProductForUpdate);
  const {
    valueSelect,
    valueTextArea,
    valueNumber,
    onChange,
    onChangeTextArea,
    onChangeNumber,

    current_media,
    setShow,
    show,
    renderItems,
    getYouTubeEmbed,
    loading,
    media,
    handleSaveEmbed,
    setUrlYouTube,
    setType,
    type,
    handleOnChange,
    getMedia,
  } = useProductsGallery();

  return (
    <Row justify="start">
      <Col
        span={24}
        style={{
          display: "flex",
          padding: 5,
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Title style={{ marginBottom: "1.5px" }} level={5}>
            Categoría principal:
          </Title>
          <Select style={{ width: 200 }} onChange={setType} value={type}>
            {/* <Option value="">Seleccionar...</Option> */}
            <Option value="image">Imagen</Option>
            <Option value="url_youtube">Video de YouTube</Option>
          </Select>
        </Col>
      </Col>
      <Divider type="horizontal" dashed />
      <Col
        span={24}
        style={{
          display: "flex",
          marginTop: "10px",
          width: "100%",
          padding: 5,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {type === "image" && (
          <UploadDragAndDrop
            fileMultiple={true}
            filePath="productsGallery"
            onUploadedFile={(files) => {
              const file = files[0];
              if(files.length === 1){
                productMediaService.create({
                  type: "image",
                  product_id: record?.id,
                  path: file.fileKey,
                  priority: 0,
                });
                // setValue("path_img", file.fileKey);
                message.success("Se ha actualizado la foto");
                getMedia();
              } else if(files.length > 1){
                files.map((fileSingle)=> {
                  productMediaService.create({
                    type: "image",
                    product_id: record?.id,
                    path: fileSingle.fileKey,
                    priority: 0,
                  });
                  // setValue("path_img", file.fileKey);
                  message.success("Se ha actualizado la foto");
                  getMedia();
                })
              }
            }}
          />
        )}
        {type === "url_youtube" && (
          <>
            <SimpleInputTextArea
              placeHolder="Escribe aquí tu link extraído de YouTube"
              value={valueTextArea}
              onChange={(e) => {
                onChangeTextArea(e);
                setUrlYouTube(e.target.value);
              }}
            />
            <Button
              type="primary"
              shape="round"
              size="large"
              style={{ margin: "10px 0" }}
              onClick={handleSaveEmbed}
            >
              Guardar
            </Button>
          </>
        )}
      </Col>
      <Divider type="horizontal" dashed />
      <Row gutter={24} style={{width: "100%"}} justify="start">
        {
          <MyList
            loading={loading}
            style={{ padding: 5 }}
            grid={{ gutter: 4, column: 4 }}
            renderItem={renderItems}
            dataSource={media.map((it: any, key) => {
              // console.log(it);
              return {
                uid: key,
                url: it.path,
                status: "done",
                name: key,
                index: it.priority,
                ...it,
              };
            })}
          />
        }
      </Row>
      {current_media && (
        <ModalGalleryMedia
          closable={true}
          // height={"50%"}
          width={"calc(50% - 20px)"}
          title={
            current_media.type === "image"
              ? current_media.path.substring(
                  current_media.path.lastIndexOf("/") + 1,
                  current_media.path.length
                )
              : "Preview"
          }
          onCancel={() => setShow(false)}
          visibleState={show}
        >
          {current_media.type === "image" ? (
            <img
              alt="example"
              style={{ width: "400px", margin: "0px 0px 20px 0px" }}
              src={`${PATH_S3_BUCKET}/${current_media.path}`}
            />
          ) : (
            <iframe
              width="100%"
              height="450"
              src={getYouTubeEmbed(current_media.url_youtube)}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              //allowfullscreen
            ></iframe>
          )}
        </ModalGalleryMedia>
      )}
    </Row>
  );
};
